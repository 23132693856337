import React from "react";
import Select from "react-select";

const ManageContent = ({
  successMessage,
  handleSubmit,
  setFormData,
  formData,
  setShowModal,
  roles,
  businesses,
  responseSuccess,
}) => {

  const options = businesses.map((business) => ({
    value: business.business_yelp_id,
    label: business.business_name,
  }));
  const handleChange = (selectedOptions) => {
    setFormData({
      ...formData,
      business_yelp_ids: selectedOptions.map((option) => option.value),
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="flex flex-grow flex-col p-4">
      <h2 className="text-2xl font-bold mb-4">Create User</h2>
      <div className="bg-white rounded-lg p-4">
        <h3 className="text-lg font-semibold mb-4 border-b pb-2">New User</h3>
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="last_name"
              className="block text-sm font-medium text-gray-700"
            >
              Last Name
            </label>
            <input
              id="last_name"
              name="last_name"
              type="text"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>

            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              required
              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <label
              htmlFor="roles_ids"
              className="block text-sm font-medium text-gray-700"
            >
              Role
            </label>
            <select
              id="roles_ids"
              name="roles_ids"
              onChange={(e) =>
                setFormData({ ...formData, roles_ids: [e.target.value] })
              }
              required

              className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="" disabled selected>
                Select a role
              </option>
              {roles.map((role) => (
                <option key={role._id} value={role._id}>
                  {role.role_name.charAt(0).toUpperCase() +
                    role.role_name.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="business_yelp_ids"
              className="block text-sm font-medium text-gray-700"
            >
              Business
            </label>

            {/* className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" */}


            <Select
              id="business_yelp_ids"
              name="business_yelp_ids"
              isMulti
              options={options}
              value={options.filter((option) =>
                formData.business_yelp_ids.includes(option.value)
              )}
              onChange={handleChange}
              className="mt-1 block w-full"
              placeholder="Select a business"
            />
          </div>
          <div className="flex justify-end col-start-1 col-span-2 md:col-span-1 md:col-start-2">
            <button
              type="submit"
              className="w-full lg:w-1/2 bg-green-500 text-white py-2 rounded hover:bg-green-600"
            >
              Create User
            </button>

          </div>
        </form>
      </div>
      {/* Mensaje de éxito */}
      {successMessage && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg z-40">
          Form submitted successfully!
        </div>
      )}
    </div>
  );
};

export default ManageContent;
