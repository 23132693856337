import React, { useState, useEffect, useMemo } from "react";


import { SpinnerCircularSplit } from 'spinners-react';
const CardsBusiness = ({ onClinicClick, business, fetchLeads, position, url }) => {
    // Lista de colores de Tailwind CSS para texto
    const tailwindTextColors = [
        "text-red-500",
        "text-blue-500",
        "text-green-500",
        "text-yellow-500",
        "text-indigo-500",
        "text-orange-500",
        "text-gray-500",
    ];

    // Función para obtener un índice de color basado en el ID del negocio
    const getColorIndexFromId = (id) => {
        let hash = 0;
        for (let i = 0; i < id.length; i++) {
            hash = (hash << 5) - hash + id.charCodeAt(i);
            hash |= 0; // Convertir a entero de 32 bits
        }
        return Math.abs(hash) % tailwindTextColors.length;
    };

    // Obtener el color dinámico para el texto
    const dynamicColor = useMemo(() => {
        return tailwindTextColors[getColorIndexFromId(business.business_yelp_id)];
    }, [business.business_yelp_id]);

    const [leads, setLeads] = useState([]);
    const [yesterDayLeads, setYesterDayLeads] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // console.log(position, "position")
        const fetchData = async () => {
            try {
                setLoading(true)
                const leadsData = await fetchLeads(business.business_yelp_id);
                setLeads(leadsData); // Actualizar el estado con los datos obtenidos

                // Calcular los leads de ayer directamente aquí
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const yesterdayDateString = yesterday.toISOString().split("T")[0];

                const yesterdayLeads = leadsData.filter((lead) => {
                    const leadDate = new Date(lead.createdAt).toISOString().split("T")[0];
                    return leadDate === yesterdayDateString;
                });

                setYesterDayLeads(yesterdayLeads); // Actualizar los leads de ayer
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error("Error fetching leads:", error);
            }
        };


        // if (url === "/Dashboard") {
        fetchData();
        // }
    }, []);

    return (
        <div
            className="flex flex-col p-4 lg:p-6 justify-between rounded-3xl shadow-xl bg-white cursor-pointer hover:shadow-2xl transition-shadow"
            onClick={() => onClinicClick(business)} // Manejar el clic
        >
            {/* Título del negocio con color dinámico */}
            <div className={`text-sm md:text-lg font-semibold mb-4 ${dynamicColor}`}>
                {business.business_name}
            </div>

            {/* Información de leads y porcentaje */}
            {!loading ? (

                <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-col">
                        <p className="text-lg font-bold">{leads.length}</p>
                        <p className="text-xs md:text-sm text-[#979797]">(Total Leads)</p>
                    </div>
                    <div className="text-xs md:text-lg font-bold text-green-500">
                        New Leads: {yesterDayLeads.length}
                    </div>
                </div>
            ) : (
                < SpinnerCircularSplit size={30} thickness={102} speed={140} color="#CB1224" secondaryColor="rgba(172, 57, 57, 0)" />

            )}
        </div>
    );
};

export default CardsBusiness;