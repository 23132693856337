import React from "react";

const Footer = () => {
    return (
        <footer className="relative bottom-0 bg-white text-white p-4 z-0">

            <div className="flex flex-col-reverse gap-4 lg:flex-row items-center justify-between">
                <p className="text-sm text-[#6C6C6C]">
                    © {new Date().getFullYear()} Azucar Inc. All Rights Reserved
                </p>
                <div className="flex space-x-4">
                    <a
                        href="#"
                        className="text-gray-400 hover:text-[#CB1224] transition-colors"
                        aria-label="Facebook"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path d="M12 2.04c-5.5 0-10 4.49-10 10.02 0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89 1.09 0 2.24.19 2.24.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7c4.78-.75 8.44-4.9 8.44-9.9 0-5.53-4.5-10.02-10-10.02z" />
                        </svg>
                    </a>
                    <a
                        href="#"
                        className="text-gray-400 hover:text-[#CB1224] transition-colors"
                        aria-label="Twitter"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path d="M22.23 5.924c-.81.36-1.68.6-2.59.71.93-.56 1.64-1.44 1.98-2.49-.87.51-1.83.88-2.85 1.08-.82-.88-2-1.43-3.3-1.43-2.5 0-4.52 2.03-4.52 4.53 0 .36.04.71.12 1.04-3.76-.19-7.09-1.99-9.32-4.73-.39.67-.61 1.45-.61 2.29 0 1.57.8 2.96 2.02 3.77-.75-.02-1.45-.23-2.06-.57v.06c0 2.19 1.56 4.02 3.63 4.44-.38.1-.78.16-1.19.16-.29 0-.57-.03-.85-.08.57 1.8 2.24 3.11 4.22 3.15-1.55 1.22-3.5 1.94-5.62 1.94-.37 0-.73-.02-1.09-.06 2 1.28 4.38 2.03 6.94 2.03 8.33 0 12.89-6.9 12.89-12.89 0-.2 0-.39-.01-.59.88-.64 1.64-1.44 2.25-2.35z" />
                        </svg>
                    </a>
                    <a
                        href="#"
                        className="text-gray-400 hover:text-[#CB1224] transition-colors"
                        aria-label="LinkedIn"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                        >
                            <path d="M19 0h-14c-2.76 0-5 2.24-5 5v14c0 2.76 2.24 5 5 5h14c2.76 0 5-2.24 5-5v-14c0-2.76-2.24-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.27c-.97 0-1.75-.79-1.75-1.75s.78-1.75 1.75-1.75 1.75.79 1.75 1.75-.78 1.75-1.75 1.75zm13.5 12.27h-3v-5.6c0-1.34-.02-3.05-1.86-3.05-1.86 0-2.14 1.45-2.14 2.95v5.7h-3v-11h2.87v1.57h.04c.48-.9 1.64-1.85 3.37-1.85 3.61 0 4.28 2.37 4.28 5.45v6.28z" />
                        </svg>
                    </a>
                </div>

            </div>
        </footer>
    );
};

export default Footer;