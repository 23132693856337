import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./components/Siderbar";
import DashboardContent from "./components/DashboardTable";
import LeadsContent from "./components/LeadsContent";
import Navbar from "../components/Navbar";
import Loading from "../components/Loading";
import axios from "axios";
import ModalCreateUser from "../components/ModalCreateUser";
// import ModalStatitics from "../components/ModalStatitics";
import EditUserConten from "./components/EditUserContent";
import ModifyUserContent from "./components/ModifyUserContent";
import ManageContent from "./components/ManageContent";
import globalVariables from "../../GlobalVariable";
import UserUpdate from "../components/ModalUserUpdate";
import Footer from "./components/Footer";
import { Edit } from "lucide-react";
import NavbarConten from "./components/NavbarConten";
import LeadForm from "./components/LeadForm";

const Dashboard = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    roles_ids: [],
    business_yelp_ids: [],
    approved: true,
  });
  const [allUsers, setAllUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolUser, setRolUser] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(true);
  const [businessName, setBusinessName] = useState("");
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [user, setUser] = useState(null);
  const [currentView, setCurrentView] = useState("dashboard"); // Estado para controlar la vista actual
  const [selectedClinic, setSelectedClinic] = useState(null); // Estado para la clínica seleccionada
  const [isOpen, setIsOpen] = useState(false);

  // Función para manejar el clic en una tarjeta de clínica
  const handleClinicClick = (clinic) => {
    setSelectedClinic(clinic); // Guardar la clínica seleccionada
    setCurrentView("leads"); // Cambiar a la vista de leads
  };

  // Función para cambiar la vista
  const handleNavigation = (view) => {
    setCurrentView(view);
    setIsOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    if (token) {
      axios
        .get(`${globalVariables.domain}/user/${user_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((response) => {
          if (response.data.success) {
            setRolUser(response.data.content);
            setUserData(response.data.content);
            setUser(response.data.content);

            const business_yelp_ids = response.data.content.business_yelp_ids;
            localStorage.setItem("business_yelp_ids", business_yelp_ids);
            setIsAuthenticated(true);

            // const timer = setTimeout(() => {
            setShowLogo(false);
            // }, 1);

            return; // () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          console.error("Error al verificar el token:", error);
          navigate("/");
        });

      axios
        .get(`${globalVariables.domain}/role`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRoles(response.data.content);
        })
        .catch((error) => {
          console.error("Error al obtener roles:", error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${globalVariables.domain}/user`, formData)
      .then((response) => {
        if (response.data.success) {
          setSuccessMessage("User created successfully!");
          setResponseSuccess(true); // Éxito
          // Limpia el formulario
          setFormData({
            name: "",
            last_name: "",
            email: "",
            password: "",
            roles_ids: [],
            business_yelp_ids: [],
            approved: true,
          });

          // Establece un temporizador
          setTimeout(() => {
            setSuccessMessage("");
            setShowModal(false);
          }, 3000);
        } else {
          setSuccessMessage(response.data.content);
          setResponseSuccess(false);
          setTimeout(() => {
            setSuccessMessage("");
            setResponseSuccess(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error);
        setSuccessMessage("An error occurred while creating the user."); // Mensaje genérico de error
        setResponseSuccess(false); // Error
      });
  };

  const [url, setUrl] = useState("");

  useEffect(() => {
    if (user !== null) {
      // console.log(user, "user2222");
      getBusiness();
    }
    // quiero saber en que url estoy
    // console.log(window.location.pathname, "url");
  }, [user]);

  const getAllUsers = async () => {
    try {
      const response = await axios.get(`${globalVariables.domain}/users/`);

      setAllUsers(response.data.content);

      // console.log(response.data.content.roles_ids.role_name, "role_name");
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const getBusiness = async () => {
    // console.log("checking business");
    try {
      const response = await axios.get(
        `${globalVariables.domain}/business/by_user/${user._id}`,
        {}
      );

      // console.log(response.data.content, "get business");

      setBusinesses(response.data.content);
      return;
    } catch (error) {
      console.error(error);
      return; // Retorna un array vacío en caso de error
    }
  };

  const handleSelectChange = (event) => {
    setSelectedBusiness(event.target.value);
  };

  // tagsForm
  const [tagsForm, setTagsForm] = useState([]);

  // tagsCall
  const [tagsYelpCall, setTagsYelpCall] = useState([]);

  // tagsGoogle
  const [tagsGoogle, setTagsGoogle] = useState([]);

  //leads_business_yelp_id

  const getLeadsSystematik2 = async (business_yelp_id) => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/leads_business_yelp_id/${business_yelp_id}`,
        {}
      );
      const systematik_leads = response.data.content;

      // console.log(systematik_leads);

      return systematik_leads;
    } catch (error) {
      console.error(error);
      return []; // Retorna un array vacío en caso de error
    }
  };

  const getLeadsSystematik = async (business_yelp_id) => {
    setTagsForm([]);
    setTagsYelpCall([]);
    setTagsGoogle([]);
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/leads/`,
        {}
      );
      const systematik_leads = response.data.content;

      if (business_yelp_id === "pJQWHLMNUfJCz786v5egcg") {
        // VMCUC
        setTagsForm([]);
        setTagsYelpCall(["(510) 398-4544"]);
        setTagsGoogle(["(510) 405-3673"]);
      }

      if (business_yelp_id === "X7Bfu5XULEm6DPF2aU5hsw") {
        // AAA

        setTagsForm(["aaa", "aaa web form"]);
        setTagsYelpCall(["(408) 713-5181"]);
        setTagsGoogle(["(408) 882-1249", "(831) 480-1788"]);
      }

      if (business_yelp_id === "yqN1wSXUiFP27Zv4Kx8Bng") {
        // Acacia

        setTagsForm([]);
        setTagsYelpCall(["(669) 303-6604"]);
        setTagsGoogle(["(669) 348-3151"]);
      }

      // Mapeo de business_yelp_id a tagPriority
      const tagPriorityMap = {
        pJQWHLMNUfJCz786v5egcg: [
          "(510) 405-3673",
          "(510) 368-4544",
          "pJQWHLMNUfJCz786v5egcg",
        ], // VMCUC
        X7Bfu5XULEm6DPF2aU5hsw: [
          "(408) 713-5181",
          "(408) 882-1249",
          "(831) 480-1788",
          "aaa",
          "aaa web form",
          "X7Bfu5XULEm6DPF2aU5hsw",
        ], // AAA
        yqN1wSXUiFP27Zv4Kx8Bng: [
          "(669) 348-3151",
          "(669) 303-6604",
          "yqN1wSXUiFP27Zv4Kx8Bng",
        ], // Acacia
      };

      const tagPriority = tagPriorityMap[business_yelp_id] || [];

      const filteredLeads = systematik_leads.filter(
        (lead) =>
          Array.isArray(lead.tags) &&
          lead.tags.some((tag) => tagPriority.includes(tag))
      );

      // console.log(filteredLeads, "filteredLeads")

      return filteredLeads;
    } catch (error) {
      console.error(error);
      return []; // Retorna un array vacío en caso de error
    }
  };

  const fetchLeads = async (business_yelp_id) => {
    try {
      const response = await axios.get(`${globalVariables.domain}/lead`, {
        headers: {
          business_yelp_id: business_yelp_id,
        },
      });

      // Obtén los leads desde getLeadsSystematik

      // const leadsSystematik = await getLeadsSystematik(business_yelp_id);
      const leadsSystematik = await getLeadsSystematik2(business_yelp_id);

      // Combina ambos arrays de leads
      const combinedLeads = [...response.data.content, ...leadsSystematik];

      return combinedLeads;
    } catch (error) {
      console.error("Error fetching leads:", error);
      return []; // Retorna un array vacío en caso de error
    }
  };

  return (
    <div className="min-h-screen">
      {isAuthenticated ? (
        showLogo ? (
          <Loading />
        ) : (
          <div className="min-h-screen ">
            {/* Main Content */}
            <div className="flex">
              {/* Sidebar */}
              <div className="flex lg:w-[15%]">
                <div className="fixed z-50">
                  <Sidebar
                    onNavigate={handleNavigation}
                    currentView={currentView}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    rolUser={rolUser.roles_ids[0]}
                  />
                </div>
              </div>

              {/* Main Area */}

              <div className="flex flex-col bg-[#F9F9F9] w-full min-h-screen">
                {/* Navbar */}
                {/* <Navbar
                  userData={userData}
                  setShowModal={setShowModal}
                  businessName={businessName}
                  formData={formData}
                  setFormData={setFormData}
                /> */}
                <NavbarConten
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  rolUser={rolUser.roles_ids[0]}
                />

                {currentView === "dashboard" && (
                  <DashboardContent
                    setSelectedClinic={setSelectedClinic}
                    fetchLeads={fetchLeads}
                    setClinic={setSelectedClinic}
                    userData={userData}
                    onClinicClick={handleClinicClick}
                    selectedClinic={selectedClinic}
                    tagsForm={tagsForm}
                    tagsGoogle={tagsGoogle}
                    tagsYelpCall={tagsYelpCall}
                    user={user}
                  />
                )}
                {currentView === "leads" && (
                  <LeadsContent
                    rolUser={rolUser.roles_ids[0]}
                    setSelectedClinic={setSelectedClinic}
                    selectedClinic={selectedClinic}
                    onClinicClick={handleClinicClick}
                    businesses={businesses}
                    fetchLeads={fetchLeads}
                    tagsForm={tagsForm}
                    tagsGoogle={tagsGoogle}
                    tagsYelpCall={tagsYelpCall}
                    isOpen={isOpen}
                    user={user}
                    className
                  />
                )}
                {currentView === "newlead" && (
                  <LeadForm
                    selectedClinic={selectedClinic}
                    businesses={businesses}
                    setSelectedClinic={setSelectedClinic}
                    user={user}
                  />
                )}

                {/* {currentView === 'statics' && <ModalStatitics />} */}
                {currentView === "edituser" && (
                  <EditUserConten
                    userData={userData}
                    setUserData={setUserData}
                    setAllUsers={setAllUsers}
                  />
                )}
                {currentView === "modifyusers" && (
                  <ModifyUserContent allUsers={allUsers} />
                )}
                {currentView === "manage" && (
                  <ManageContent
                    successMessage={successMessage}
                    responseSuccess={responseSuccess}
                    setShowModal={setShowModal}
                    roles={roles}
                    formData={formData}
                    setFormData={setFormData}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    showModal={showModal}
                    selectedBusiness={selectedBusiness}
                    handleSelectChange={handleSelectChange}
                    businesses={businesses}
                  />
                )}
                <Footer />
              </div>
            </div>
          </div>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;
