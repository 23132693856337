import React, { useState, useEffect } from "react";
import axios from "axios";
import globalVariables from "../../../GlobalVariable";

const LeadForm = ({ selectedClinic, businesses, setSelectedClinic, user }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    serviceNeeded: "",
    leadSource: "Manual",
    notes: "",
    tags: [selectedClinic.business_yelp_id, "manual lead"],
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Estado para mostrar el mensaje de éxito

  const handleChange = (e) => {
    if (e.target.name === "business") {
      getBusiness(e.target.value);
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submit_form(formData); // Envía los datos del formulario
  };

  const submit_form = async (data) => {
    try {
      const response = await axios.post(
        `${globalVariables.domain}/lead_systematik/`,
        {
          business_yelp_id: selectedClinic.business_yelp_id,
          conversationId: "N/A",
          locationId: "manual",
          contactName: data.name,
          phone: data.phone,
          email: data.email,
          service: data.serviceNeeded,
          notes: data.notes,
          tags: data.tags,
          lead_source: "manual",
        }
      );

      // Mostrar mensaje de éxito
      setShowSuccessMessage(true);

      // Reiniciar el formulario
      setFormData({
        name: "",
        email: "",
        phone: "",
        serviceNeeded: "",
        notes: "",
        tags: ["manual lead"],
      });

      // Ocultar el mensaje después de 3 segundos
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      console.log(response.data, "create");
    } catch (error) {
      console.error(error);
    }
  };

  const getBusiness = async (id) => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/business/by_user/${id}`
      );
      setSelectedClinic(response.data.content);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  // useEffect(() => {
  //   console.log(user, "user");
  // }, []);

  return (
    <div className="flex flex-grow flex-col mx-2 lg:mx-0 gap-4 p-4 mb-4">
      <h2 className="text-2xl font-bold mb-6 text-start">
        Enter New Lead For {selectedClinic.business_name}
      </h2>
      {user.business_yelp_ids.length > 1 && (
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="company"
          >
            Business
          </label>
          <select
            id="business"
            name="business"
            value={formData.business}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
            required
          >
            <option value="" disabled>
              Select a business
            </option>
            {(businesses || []).map((business) => (
              <option
                key={business.business_yelp_id}
                value={business.business_yelp_id}
              >
                {business.business_name}
              </option>
            ))}
          </select>
        </div>
      )}
      <form
        className="bg-white p-4 rounded-lg shadow-lg"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {/* Name Field */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter name"
              required
            />
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter email"
            />
          </div>

          {/* Phone Field */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Phone
            </label>
            <input
              type="number"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter phone number"
            />
          </div>

          {/* Service Needed Field (Select) */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="serviceNeeded"
            >
              Service Needed
            </label>
            <select
              id="serviceNeeded"
              name="serviceNeeded"
              value={formData.serviceNeeded}
              onChange={handleChange}
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
              required
            >
              <option value="" disabled>
                Select a service
              </option>
              {(selectedClinic?.services || []).map((service) => (
                <option key={service} value={service}>
                  {service}
                </option>
              ))}
            </select>
          </div>

          {/* Customer Notes Field */}
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="customerNotes"
            >
              Customer Notes
            </label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter customer notes"
              rows="4"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save Lead
          </button>
        </div>
      </form>

      {/* Mensaje de éxito */}
      {showSuccessMessage && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg">
          Form submitted successfully!
        </div>
      )}
    </div>
  );
};

export default LeadForm;
