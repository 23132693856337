"use client";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faBan,
  faClock,
  faLock,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import globalVariables from "../../../GlobalVariable";
import Yelp from "../../../img/yelp.png";
import google from "../../../img/google.png";
import internet from "../../../img/internet.png";
import yelp_call from "../../../img/yelp_call.png";
import aaa_call_center from "../../../img/aaa_call_center.png";
import aaa_form_website from "../../../img/aaa_form_website.png";
import aaron_web_form_image from "../../../img/aaron_web_form_image.png";
import aaron_web_form_organic_image from "../../../img/aaron_web_form_organic_image.png";
import voice_ai from "../../../img/voice_ai.png";
import manual_lead from "../../../img/manual_lead.png";
import ModalListenRecording from "../../components/ModalListenRecording";
import { ChevronDown } from "lucide-react";
import ModalRate from "../../components/ModalRate";
import ModalStatitics from "./ModalStatitics";

const getStatusIcon = (status, isOpen) => {
  switch (status) {
    case "fire":
      return (
        <div className="flex justify-center">
          {!isOpen && (
            <FontAwesomeIcon
              icon={faFire}
              className="animate-jump animate-infinite animate-duration-[1000ms] animate-delay-1000 animate-ease-in text-orange-500 fa-xl z-10"
            />
          )}
        </div>
      );
    case "no interested":
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faBan} className="text-red-500 fa-xl" />
        </div>
      );
    case "30 days time":
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faClock} className="text-blue-500 fa-xl" />;
        </div>
      );
    case "blocked us":
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faLock} className="text-red-500 fa-xl" />
        </div>
      );
    case "called/emailed":
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon
            icon={faEnvelopeOpen}
            className="text-green-500 fa-xl"
          />
        </div>
      );
    default:
      return (
        <div className="flex justify-center">
          <FontAwesomeIcon icon={faFire} className="text-gray-500 fa-xl" />
        </div>
      );
  }
};

const LeadScriptToggle = ({
  leadId,
  initialScriptState,
  onUpdate,
  fetchLeads,
  business_yelp_id,
}) => {
  const [scriptIsOn, setScriptIsOn] = useState(
    initialScriptState === null || initialScriptState === undefined
      ? true
      : initialScriptState
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialScriptState === null || initialScriptState === undefined) {
      updateLeadScript(true, business_yelp_id);
    }
  }, []);

  const updateLeadScript = async (newState, business_yelp_id) => {
    try {
      await axios.patch(`${globalVariables.domain}/lead/${leadId}`, {
        script_is_on: newState,
      });
      setScriptIsOn(newState);
      fetchLeads(business_yelp_id);

      onUpdate(newState);
    } catch (error) {
      console.error("Error updating lead script state:", error);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleOptionClick = (value) => {
    updateLeadScript(value === "on", business_yelp_id);
    setIsOpen(false);
  };

  return (
    <div className="flex">
      <button
        onClick={toggleDropdown}
        className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="flex items-center">
          <span
            className={`w-2.5 h-2.5 mr-2 rounded-full ${
              scriptIsOn ? "bg-green-500" : "bg-red-500"
            }`}
          ></span>
          {scriptIsOn ? "ON" : "OFF"}
        </span>
        <svg
          className="w-5 h-5 ml-2 -mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <ul
          className="absolute z-10 w-full mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
          tabIndex="-1"
          role="listbox"
        >
          {["on", "off"].map((option) => (
            <li
              key={option}
              className={`cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-indigo-600 hover:text-white ${
                scriptIsOn === (option === "on") ? "bg-indigo-100" : ""
              }`}
              onClick={() => handleOptionClick(option)}
              role="option"
              aria-selected={scriptIsOn === (option === "on")}
            >
              <div className="flex items-center">
                <span
                  className={`w-2.5 h-2.5 mr-2 rounded-full ${
                    option === "on" ? "bg-green-500" : "bg-red-500"
                  }`}
                ></span>
                <span
                  className={`font-normal block truncate ${
                    scriptIsOn === (option === "on") ? "font-semibold" : ""
                  }`}
                >
                  {option.toUpperCase()}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const LeadTable = ({
  rolUser,
  leads,
  setLeads,
  fetchLeads,
  selectedClinic,
  isRecientTable,
  businesses,
  isOpen,
  onClinicClick,
  setSelectedClinic,
  user,
  get_data,
}) => {
  // const [leads, setLeads] = useState([]);
  const [sortBy, setSortBy] = useState("timeDesc");
  const [expandedLeadId, setExpandedLeadId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [updatingLeadId, setUpdatingLeadId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isInfoOpenRate, setisInfoOpenRate] = useState(false);
  const [isInfoOpenRecording, setIsInfoOpenRecording] = useState(false);

  const leadsPerPage = 10;

  const [yelpIds, setYelpIds] = useState("");

  const [mapping, setMapping] = useState([]);

  const mapping_air_conditioning = {
    "Dryer Vent Cleaning": "Dryer Vent Cleaning",
    "Duct Cleaning": "Duct Cleaning",
    "Duct Cleaning, Dryer Vent Cleaning": "Duct Cleaning",
    "Air ducts and Dryer Vent Cleaning": "Duct Cleaning",
    "Water Heater Repair": "Water Heater Repair",
    "Water heater repair": "Water Heater Repair",
    "Water heater installation": "Water Heater Installation",
    "Water Heater Installation": "Water Heater Installation",
    "Installation or replacement": "Installation or Replacement",
    "Thermostat Installation": "Thermostat Installation",
    "Thermostat installation": "Thermostat Installation",
    "Service Calls": "Service Calls",
    Maintenance: "Maintenance",
    "Furnace Replacement": "Furnace Replacement",
    "Furnace Repair": "Furnace Repair",
    "Furnace replacement or repair": "Furnace Repair",
    "Tankless Water Heater Maintenance": "Water Heater Maintenance",
    "Plumbing Service": "Plumbing Service",
    // Agregar más mapeos según sea necesario
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    serviceNeeded: "",
    leadSource: "Manual",
    notes: "",
    tags: "manual lead",
  });
  const isAdmin = rolUser === "66ec7fc619e08e4bf824f210";

  function truncarTexto(texto) {
    // Verifica si el texto es undefined, null o no es un string
    if (typeof texto !== "string" || !texto) {
      return texto; // Devuelve el valor original si no es un string válido
    }

    // Trunca el texto si es más largo que 10 caracteres
    if (texto.length > 10) {
      return texto.substring(0, 10) + "...";
    }

    // Devuelve el texto original si tiene 10 caracteres o menos
    return texto;
  }

  //expandedLeadId

  useEffect(() => {
    if (selectedClinic.length > 0) {
      if (selectedClinic.business_type === "clinic vet") {
        setMapping(false);
      } else if (selectedClinic.business_type === "air conditioning") {
        setMapping(mapping_air_conditioning);
      }
    }
  }, [selectedClinic]);

  // tagsForm
  const [tagsForm, setTagsForm] = useState([]);

  // tagsCall
  const [tagsYelpCall, setTagsYelpCall] = useState([]);

  // tagsGoogle
  const [tagsGoogle, setTagsGoogle] = useState([]);

  useEffect(() => {
    if (selectedClinic.business_yelp_id === "pJQWHLMNUfJCz786v5egcg") {
      // console.log("checkpoint");
      // VMCUC
      setTagsForm([]);
      setTagsYelpCall(["(510) 398-4544"]);
      setTagsGoogle(["(510) 405-3673"]);
    }

    if (selectedClinic.business_yelp_id === "X7Bfu5XULEm6DPF2aU5hsw") {
      // AAA

      setTagsForm(["aaa", "aaa web form"]);
      setTagsYelpCall(["(408) 713-5181"]);
      setTagsGoogle(["(408) 882-1249", "(831) 480-1788"]);
    }

    if (selectedClinic.business_yelp_id === "yqN1wSXUiFP27Zv4Kx8Bng") {
      // Acacia

      setTagsForm([]);
      setTagsYelpCall(["(669) 303-6604"]);
      setTagsGoogle(["(669) 348-3151"]);
    }
  }, [selectedClinic]);

  const [businessSelected, setBusinessSelected] = useState("");
  const [modalStatitics, setModalStatitics] = useState(true);

  useState(() => {
    const businessYelpIds = localStorage.getItem("business_yelp_ids");
    // console.log(businessYelpIds, "eeeeeeeeee");

    if (!businessYelpIds.includes(",")) {
      setYelpIds([businessYelpIds]);
      // fetchLeads(businessYelpIds);
      setBusinessSelected(businessYelpIds);
    } else {
      // Si la cadena contiene valores separados por comas, conviértelo en un array
      const businessYelpIdsArray = businessYelpIds
        ? businessYelpIds.split(",")
        : [];

      // Verifica el resultado
      // console.log(businessYelpIdsArray);

      setYelpIds(businessYelpIdsArray);
    }
  }, []);

  const updateLeadStatus = async (lead_id, newStatus) => {
    setUpdatingLeadId(lead_id);
    setErrorMessage("");
    try {
      const response = await axios.patch(
        `${globalVariables.domain}/lead/${lead_id}`,
        {
          lead_status: newStatus,
        }
      );

      if (response.data.success && response.data.content === null) {
        // throw new Error("Server returned null content");
      }

      setLeads(
        leads.map((lead) =>
          lead._id === lead_id ? { ...lead, lead_status: newStatus } : lead
        )
      );
      alert("Lead status updated successfully!");
    } catch (error) {
      console.error("Error updating lead status:", error);
      setErrorMessage("Failed to update lead status. Please try again.");
      setLeads(
        leads.map((lead) =>
          lead._id === lead_id
            ? { ...lead, lead_status: lead.lead_status }
            : lead
        )
      );
    } finally {
      setUpdatingLeadId(null);
    }
  };

  const handleSortChange = (event) => {
    // console.log(event.target.value, "event");

    setCurrentPage(1);
    setSortBy(event.target.value);
    setSearchValue("");
  };

  const [SearchValue, setSearchValue] = useState("");

  const handleSortInputChange = (value) => {
    // console.log(value, "value");
    setSearchValue(value);

    if (value === "") {
      // timeDesc;
      setSortBy("timeDesc");
      setCurrentPage(1);
    } else {
      setSortBy(value);
      setCurrentPage(1);
    }
  };

  const sortedLeads = [...leads].sort((a, b) => {
    const aIsHotLead = a.lead_status === "fire";
    const bIsHotLead = b.lead_status === "fire";

    const dateA = a.lastMessageDate
      ? new Date(a.lastMessageDate * 1000)
      : new Date(0);
    const dateB = b.lastMessageDate
      ? new Date(b.lastMessageDate * 1000)
      : new Date(0);

    // Priorizar "hotLeads"
    if (sortBy === "hotLeads") {
      if (aIsHotLead !== bIsHotLead) return aIsHotLead ? -1 : 1;
      return dateB - dateA;
    }

    // Ordenar por tiempo
    if (sortBy === "timeAsc") return dateA - dateB;
    if (sortBy === "timeDesc") return dateB - dateA;

    // Filtro de búsqueda
    if (typeof sortBy === "string" && sortBy.length > 0) {
      const getAllMatches = (lead) =>
        [lead.name, lead.fullName, lead.phone].some((field) =>
          field?.toLowerCase().includes(sortBy.toLowerCase())
        );

      const aMatches = getAllMatches(a);
      const bMatches = getAllMatches(b);

      if (aMatches !== bMatches) return aMatches ? -1 : 1;
    }

    // Ordenar por fecha si no se cumple ningún otro criterio
    return dateA - dateB;
  });

  const toggleLeadExpansion = (leadId) => {
    setExpandedLeadId(expandedLeadId === leadId ? null : leadId);
  };

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = sortedLeads.slice(indexOfFirstLead, indexOfLastLead);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(leads.length / leadsPerPage);
  const maxButtons = 3; // Número máximo de botones

  // Calcular el rango de botones a mostrar
  let startPage = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
  let endPage = startPage + maxButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(endPage - maxButtons + 1, 1);
  }

  const updateLeadScriptState = (leadId, newState) => {
    setLeads(
      leads.map((lead) =>
        lead._id === leadId ? { ...lead, script_is_on: newState } : lead
      )
    );
  };

  const showMessages = (lead) => {
    // console.log(lead, "messageeeeee");
    // return "";

    if (lead.locationId !== undefined) {
      return "";
    }

    if (lead.messages.length === 0) {
      return <>No Data Found</>;
    }
    return (
      <>
        <p className="text-xl font-bold mb-6">Message 1:</p>
        <p>{lead.messages[1][0].message}</p>
        {lead.messages[2] !== undefined &&
          lead.messages[2][0] !== undefined && (
            <>
              <p className="text-xl font-bold my-6">Message 2:</p>
              <p>{lead.messages[2][0].message}</p>
            </>
          )}
        <a
          className="flex flex-col mt-4"
          href={lead.link_to_conversation_yelp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong className="font-semibold px-2 text-orange-500">
            More...
          </strong>
        </a>
      </>
    );
  };

  const leadsQuestions = (lead) => {
    if (lead.locationId !== undefined) {
      return "";
    }

    if (lead.messages.length === 0) {
      return <>No Data Found</>;
    }
    // return lead.messages.map((message, index) => {

    const originalMessage = lead.messages[0].message;

    if (originalMessage === undefined) {
      return <>No Data Found</>;
    }

    let cleanMessage = "";

    if (
      originalMessage.includes(
        "Hi there, I'd like to have a consultation. Here are my answers to Yelp's questions regarding my request:"
      )
    ) {
      cleanMessage = originalMessage.replace(
        "Hi there, I'd like to have a consultation. Here are my answers to Yelp's questions regarding my request:",
        ""
      );
    } else if (
      originalMessage.includes(
        "Hi there! Could you help me with my project? Here are my answers to Yelp's questions regarding my project:"
      )
    ) {
      cleanMessage = originalMessage.replace(
        "Hi there! Could you help me with my project? Here are my answers to Yelp's questions regarding my project:",
        ""
      );
    }

    const question1 = "Service Needed";
    const answer1 = lead.service_type;

    // const question2 = "What type of animal needs care?";
    // const answer2 = lead.pet_type;

    const question2 = "";
    const answer2 = "";

    const question3 = "Does the pet or animal need in-home service?";
    let answer3 = "";

    if (cleanMessage.includes("Yes")) {
      answer3 = "Yes";
    } else if (cleanMessage.includes("No")) {
      answer3 = "No";
    }

    const question4 = "What type of dental care does your pet or animal need?";
    let answer4 = getDetailsResponse(
      cleanMessage,
      "What type of dental care does your pet or animal need?",
      "In what location do you need the service?"
    );

    const question5 = "Any details you would like to add?";
    let answer5 = getDetailsResponse(
      cleanMessage,
      "Any details you'd like to add?",
      "In what location do you need the service?"
    );

    const question6 = "When do you require this service?";
    let answer6 = getDetailsResponse(
      cleanMessage,
      "When do you require this service?",
      "In what location do you need the service?"
    );

    const locationQuestion = "In what location do you need the service?";
    const location = getLastWord(cleanMessage);

    let messages = "";
    // if (index === 0) {
    messages = (
      <>
        <p className="text-xl font-bold mb-6">Lead questions:</p>
        {/* {cleanMessage} */}
        <div className="mt-4">
          <p>{question1}</p>
          <b>{answer1}</b>
        </div>

        <div className="mt-4">
          <p>{question2}</p>
          <b>{answer2}</b>
        </div>

        {/* {answer3 !== "" && (
          <div className="mt-4">
            <p>{question3}</p>
            <b>{answer3}</b>
          </div>
        )} */}

        {answer4 !== "" && (
          <div className="mt-4">
            <p>{question4}</p>
            <b>{answer4}</b>
          </div>
        )}

        {answer5 !== "" && (
          <div className="mt-4">
            <p>{question5}</p>
            <b>{answer5}</b>
          </div>
        )}

        {answer6 !== "" && (
          <div className="mt-4">
            <p>{question6}</p>
            <b>{answer6}</b>
          </div>
        )}

        {location !== "" && (
          <div className="mt-4">
            <p>{locationQuestion}</p>
            <b>{location}</b>
          </div>
        )}
      </>
    );
    // }

    return messages;
    // });
  };

  function getLastWord(text) {
    const trimmedText = text.trim(); // Elimina espacios en blanco al inicio y al final
    const lastSpaceIndex = trimmedText.lastIndexOf("?"); // Encuentra el último espacio

    if (lastSpaceIndex === -1) {
      return ""; // Si no hay espacios, devuelve todo el texto
    }

    return trimmedText.substring(lastSpaceIndex + 1); // Devuelve la cadena después del último espacio
  }

  function getDetailsResponse(message, questionParameter, postQuestion) {
    const question = questionParameter;
    const endMarker = postQuestion;

    // Buscar la posición donde empieza la pregunta
    const questionIndex = message.indexOf(question);

    if (questionIndex === -1) {
      return "";
    }

    // Buscar la posición donde empieza la pregunta de la ubicación
    const endMarkerIndex = message.indexOf(endMarker);

    if (endMarkerIndex === -1) {
      return "";
    }

    // Extraer la respuesta entre la pregunta y el marcador final
    let answer = message
      .substring(questionIndex + question.length, endMarkerIndex)
      .trim();

    if (answer.includes("When do you require this service?")) {
      const phraseIndex = answer.indexOf("When do you require this service?");
      answer = answer.substring(0, phraseIndex).trim();
    }

    return answer || "";
  }

  const countBizMessages = (lead) => {
    if (lead.locationId !== undefined) {
      return "?";
    }
    let count = 0;

    const messages = lead.messages;

    for (let i = 0; i < messages.length; i++) {
      if (
        i > 0 &&
        messages[i][0] !== undefined &&
        messages[i][0].userType === "BIZ"
      ) {
        count += 1;
      }
    }
    return count.toString();
  };

  const [businessAvalaible, setBusinessAvalaible] = useState("");

  const getBusiness = async () => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/business/by_user/${user._id}`,
        {}
      );

      const allBusiness = response.data.content;

      const businessPermited = allBusiness.filter((business) =>
        yelpIds.includes(business.business_yelp_id)
      );

      // Actualizar el estado con los negocios permitidos
      setBusinessAvalaible(businessPermited);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBusiness();
  }, []);

  const chooseImgSource = (lead) => {
    const w = "w-10";
    if (lead.locationId === undefined) {
      // return <img src={Yelp} alt="Yelp" className={w} />;
      return <a>Yelp Text</a>;
    } else {
      if (lead.lead_source === "google") {
        // return <img src={google} alt="google" className={w} />;
        return <a>Google Ad</a>;
      }

      if (
        lead.lead_source === "form" &&
        lead.business_yelp_id === "X7Bfu5XULEm6DPF2aU5hsw"
      ) {
        // return (
        //   <img src={aaa_form_website} alt="aaa_form_website" className={w} />
        // );
        return <a>Web Form</a>;
      }

      if (
        lead.lead_source === "form" &&
        lead.business_yelp_id === "wxAl4NGuDUhwHgCcllvTMw" &&
        lead.tags.includes("aaron web form")
      ) {
        // return (
        //   <img
        //     src={aaron_web_form_image}
        //     alt={`aaron_web_form_image`}
        //     className={w}
        //   />
        // );

        return <a>Web Form</a>;
      }
      // Aarons Organic
      if (
        lead.lead_source === "form" &&
        lead.business_yelp_id === "wxAl4NGuDUhwHgCcllvTMw" &&
        lead.tags.includes("aaron web form organic")
      ) {
        // return (
        //   <img
        //     src={aaron_web_form_organic_image}
        //     alt={`aaron_web_form_organic_image`}
        //     className={w}
        //   />
        // );
        return <a>Web Form Org</a>;
      }

      if (lead.lead_source === "yelp_call") {
        // return <img src={yelp_call} alt="yelp_call" className={w} />;
        return <a>Yelp Call</a>;
      }

      if (lead.lead_source === "voice_ai") {
        // return <img src={voice_ai} alt="voice_ai" className={w} />;
        return <a>AI Voice</a>;
      }

      // return <img src={manual_lead} alt="manual_lead" className={w} />;
      return <a>Manual</a>;
    }
  };

  const [messagesShow, setMessagesShow] = useState([]);
  const [messageSelected, setMessageSelected] = useState("");

  const getRecording = async (id, locationId) => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/recording`,
        {
          headers: {
            message_id: id,
            location_id: locationId,
          },
          responseType: "arraybuffer",
        }
      );

      // console.log(response.data, "response.data");

      return true;
    } catch (error) {
      console.error("Error al obtener la grabación:", error);
      return false;
    }
  };

  const messages = async (lead) => {
    if (lead.conversationId === "N/A") {
      setMessagesShow([]);
      return;
    }

    if (lead.type === "call_rail") {
      await messages_call_rail(lead);
      return;
    }

    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/messages/${lead.conversationId}`,
        {}
      );

      setMessagesShow(response.data.content.messages);

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const messages_call_rail = async (lead) => {
    try {
      const response = await axios.get(
        `${globalVariables.domain}/call_leads/messages/${lead.conversationId}`,
        {}
      );

      setMessagesShow(response.data.content.messages);

      return;
    } catch (error) {
      console.error(error);
      return;
    }
  };

  useEffect(() => {
    // console.log("cambio");
    if (expandedLeadId === null) {
      return;
    }
    if (!expandedLeadId || !currentLeads) return; // Asegúrate de que los datos existan

    // Busca el lead correspondiente en currentLeads
    const lead = Array.isArray(currentLeads)
      ? currentLeads.find((lead) => lead._id === expandedLeadId) // Si es un arreglo
      : Object.values(currentLeads).find((lead) => lead._id === expandedLeadId); // Si es un objeto

    if (lead) {
      messages(lead);
    } else {
      console.warn("Lead no encontrado");
    }
  }, [expandedLeadId]); // Escucha cambios en expandedLeadId o currentLeads

  const [isModalOpenRecording, setIsModalOpenRecording] = useState(false);

  const handleCloseModalRecording = () => {
    setIsModalOpenRecording(false);
  };

  const handleOpenModalRecording = (message) => {
    // console.log(message, "message");
    // return;
    setMessageSelected(message);
    setIsModalOpenRecording(true);
    setIsInfoOpenRecording(!isInfoOpenRecording);
    // setIsMenuOpenAllUsers(true);
  };

  const [isModalOpenRate, setIsModalOpenRate] = useState(false);

  const handleCloseModalRate = () => {
    setIsModalOpenRate(false);
  };

  const handleOpenModalRate = (message) => {
    setMessageSelected(message);
    setIsModalOpenRate(true);
    setisInfoOpenRate(!isInfoOpenRate);
    // setIsMenuOpenAllUsers(true);
  };

  const handleChange = (e) => {
    if (e.target.name === "business") {
      getBusiness(e.target.value);
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // useEffect(() => {
  //   console.log(user);
  // }, [user]);

  const formatCreatedAt = (lastMessageDate) => {
    if (!lastMessageDate) return "N/A"; // Manejo de valores nulos o indefinidos

    return new Date(lastMessageDate).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  const refreshLeads = async () => {
    get_data();
  };

  return (
    <div className="w-full p-2">
      {/* {isModalOpenRecording && (
        <ModalListenRecording
          handleCloseModalRecording={handleCloseModalRecording}
          messageSelected={messageSelected}
          isModalOpenRecording={isModalOpenRecording}
        />
      )} */}
      {!isRecientTable && user.business_yelp_ids.length > 1 && (
        <div className="mb-4 p-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="company"
          >
            Business
          </label>

          <select
            id="business"
            name="business"
            value={selectedClinic?.business_yelp_id || ""}
            onChange={(e) => {
              setLeads([]);
              const selectedBusiness = businesses.find(
                (b) => b.business_yelp_id === e.target.value
              );
              setSelectedClinic(selectedBusiness);
            }}
            className="shadow border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
            required
          >
            <option value="" disabled>
              Select a business
            </option>
            {(businesses || []).map((business) => (
              <option
                key={business.business_yelp_id}
                value={business.business_yelp_id}
              >
                {business.business_name}
              </option>
            ))}
          </select>

          {/* <select
            id="business"
            name="business"
            value=""
            onChange={(e) => {
              const selectedBusiness = businesses.find(
                (b) => b.business_yelp_id === e.target.value
              );
              setSelectedClinic(selectedBusiness);
            }}
            className="shadow border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
            required
          >
            <option value="" disabled>Select a business</option>
            {(businesses || []).map((business) => (
              <option key={business.business_yelp_id} value={business.business_yelp_id}>
                {business.business_name}
              </option>
            ))}
          </select> */}
        </div>
      )}
      {/* {businessSelected && <span>Selected Business: {businessSelected}</span>} */}

      <div className="w-full z-0">
        {modalStatitics &&
          expandedLeadId === null &&
          isRecientTable === false &&
          !isOpen && (
            <ModalStatitics
              setModalStatitics={setModalStatitics}
              leads={leads}
              modalStatitics={modalStatitics}
              mapping={mapping}
              tagsForm={tagsForm}
              tagsGoogle={tagsGoogle}
              tagsYelpCall={tagsYelpCall}
            />
          )}
        {currentLeads.map((lead) => (
          <React.Fragment key={lead._id}>
            {expandedLeadId === lead._id && (
              <div className="border-b border-gray-500 bg-gray-50 mt-8 z-10">
                <div className="">
                  {/* <div className="absolute min-h-[200%] w-full lg:w-[85%] lg:px-8 bg-[#F9F9F9] z-50"> */}
                  <div className="flex flex-row p-4 lg:pb-4 lg:pr-4 justify-between">
                    <h2 className="text-3xl font-bold mb-4">Lead Detail</h2>
                    <button
                      onClick={() => toggleLeadExpansion(lead._id)}
                      className="flex flex-row gap-2 items-center shadow-lg bg-white hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-4"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Back</span>
                    </button>
                  </div>

                  {/* infomation */}

                  <div className="flex flex-col mx-2 lg:mx-0 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                    <h3 className="text-xl font-bold mb-4 border-b pb-4">
                      Information
                    </h3>
                    <div className="grid grid-cols-2 lg:grid-cols-6">
                      <div className="flex flex-col-reverse lg:flex-col gap-4 p-2">
                        <div className="flex flex-col gap-2">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            Name Client
                          </h4>
                          <p>
                            {lead.name ||
                              lead.fullName ||
                              lead.contactName ||
                              lead.phone}
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            source
                          </h4>
                          <div className="px-4">{chooseImgSource(lead)}</div>
                        </div>
                      </div>
                      <div className="flex flex-col lg:col-span-2 gap-4 p-2">
                        <div className="flex flex-col lg:border-l lg:pl-10 gap-2">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            LEAD PURPOSE
                          </h4>
                          <span>
                            {lead.service_type ||
                              lead.tags[0]?.service ||
                              "Call"}
                          </span>
                        </div>
                        <div className="flex flex-col lg:border-l lg:pl-10 gap-2">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            Lead questions
                          </h4>
                          <span>
                            {lead.service_type ||
                              lead.tags[0]?.service ||
                              "Call"}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col lg:col-span-2 gap-4 p-2">
                        <div className="flex flex-col lg:border-l lg:pl-10 gap-2">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            Lead submission time
                          </h4>
                          <div>
                            <span className="">
                              {formatCreatedAt(lead.lastMessageDate)}
                            </span>

                            <span className=""> at </span>

                            <span className="">
                              {new Date(lead.lastMessageDate).toLocaleString(
                                "en-US",
                                {
                                  timeZone: "America/Los_Angeles",
                                  hour12: true,
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                }
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col lg:border-l lg:pl-10 gap-2">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            Service Needed
                          </h4>
                          <span>
                            {lead.service_type ||
                              lead.tags[0]?.service ||
                              "Call"}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-4 p-2">
                        <div className="flex flex-col lg:border-l lg:pl-10 gap-2">
                          <h4 className="text-sm text-[#CCCCCC] uppercase">
                            Type
                          </h4>
                          <span>
                            {lead.service_type ||
                              lead.tags[0]?.service ||
                              "Call"}
                          </span>
                        </div>
                        {lead.locationId === undefined && (
                          <div className="flex flex-col lg:border-l lg:pl-10 gap-2">
                            <h4 className="text-sm text-[#CCCCCC] uppercase">
                              Script
                            </h4>

                            <LeadScriptToggle
                              leadId={lead.lead_id}
                              fetchLeads={fetchLeads}
                              initialScriptState={lead.script_is_on}
                              onUpdate={(newState) =>
                                updateLeadScriptState(lead.lead_id, newState)
                              }
                              business_yelp_id={selectedClinic.business_yelp_id}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {lead.locationId === undefined ? (
                    <>
                      {/* Lead Status */}

                      <div className="flex flex-col mx-2 lg:mx-0 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                        <div className="flex flex-col lg:flex-row justify-between">
                          <h3 className="text-xl font-bold">Lead Status</h3>
                          <div>
                            <div className="flex gap-2 items-center space-x-2">
                              <span>Status:</span>
                              <span className="ml-2">
                                {getStatusIcon(lead.lead_status, isOpen)}
                              </span>
                              <select
                                value={lead.lead_status || ""}
                                onChange={(e) => {
                                  const newLeads = leads.map((l) =>
                                    l.lead_id === lead.lead_id
                                      ? {
                                          ...l,
                                          lead_status: e.target.value,
                                        }
                                      : l
                                  );
                                  setLeads(newLeads);
                                }}
                                className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                <option value="">Select status</option>
                                <option value="fire">Fire</option>
                                <option value="no interested">
                                  Not Interested
                                </option>
                                <option value="30 days time">
                                  30 Days Time
                                </option>
                                <option value="blocked us">Blocked Us</option>
                                <option value="called/emailed">
                                  Called/Emailed
                                </option>
                              </select>
                              <button
                                onClick={() =>
                                  updateLeadStatus(
                                    lead.lead_id,
                                    lead.lead_status
                                  )
                                }
                                disabled={updatingLeadId === lead._id}
                                className={`w-full p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                                  updatingLeadId === lead._id
                                    ? "opacity-50 cursor-not-allowed"
                                    : ""
                                }`}
                              >
                                {updatingLeadId === lead._id
                                  ? "Updating..."
                                  : "Save Status"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Messages */}

                      <div className="flex flex-col mx-2 lg:mx-0 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                        <div className="flex flex-col pb-2">
                          {showMessages(lead)}
                        </div>
                      </div>
                      {/* Questions */}
                      <div className="flex flex-col mx-2 lg:mx-0 mb-20 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                        <div className="flex flex-col pb-2">
                          {leadsQuestions(lead)}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="grid grid-cols-1 lg:grid-cols-3 mx-2 lg:mx-0 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                        {lead.fullName && (
                          <div>
                            <strong>Name: </strong>
                            <span>{lead.contactName || lead.fullName}</span>
                          </div>
                        )}

                        <div>
                          <strong>Phone: </strong>
                          <a
                            style={{ color: "blue" }}
                            href={`tel:${lead.phone}`}
                          >
                            <span>{lead.phone}</span>
                          </a>
                        </div>

                        {lead.lastMessageBody &&
                          lead.locationId !== "AAA Call Center" && (
                            <div>
                              <strong>Last Message: </strong>
                              <span>{lead.lastMessageBody}</span>
                            </div>
                          )}

                        {lead.lastMessageType &&
                          lead.locationId !== "AAA Call Center" && (
                            <div>
                              <strong>Last Message Type: </strong>
                              <span>{lead.lastMessageType}</span>
                            </div>
                          )}

                        {lead.locationId === "AAA Call Center" ||
                          (lead.locationId === "AAA Form Website" && (
                            <>
                              <div>
                                <strong>Source: </strong>
                                <span>{lead.locationId}</span>
                              </div>

                              {lead.email && (
                                <div>
                                  <strong>Email: </strong>
                                  <span>{lead.email}</span>
                                </div>
                              )}

                              {lead.tags[0]?.urgency && (
                                <div>
                                  <strong>Urgency: </strong>
                                  <span>{lead.tags[0].urgency}</span>
                                </div>
                              )}

                              {lead.tags[0]?.address && (
                                <div>
                                  <strong>Address: </strong>
                                  <span>{lead.tags[0].address}</span>
                                </div>
                              )}

                              {lead.tags[0]?.serviceNeeded && (
                                <div>
                                  <strong>Service Needed: </strong>
                                  <span>{lead.tags[0].serviceNeeded}</span>
                                </div>
                              )}

                              {lead.tags[0]?.service && (
                                <div>
                                  <strong>Service Needed: </strong>
                                  <span>{lead.tags[0].service}</span>
                                </div>
                              )}

                              {lead.tags[0]?.otherNeeds && (
                                <div>
                                  <strong>Other Needed: </strong>
                                  <span>{lead.tags[0].otherNeeds}</span>
                                </div>
                              )}

                              {lead.tags[0]?.otherDetails && (
                                <div>
                                  <strong>Other Details: </strong>
                                  <span>{lead.tags[0].otherDetails}</span>
                                </div>
                              )}

                              {lead.tags[0]?.preferredDateTime && (
                                <div>
                                  <strong>Preferred Date: </strong>
                                  <span>
                                    {new Date(
                                      lead.tags[0].preferredDateTime
                                    ).toLocaleString("en-US", {
                                      dateStyle: "medium",
                                      timeStyle: "short",
                                    })}
                                  </span>
                                  {/* Ejemplo formateado: Dec 23, 2023, 1:04 PM */}
                                </div>
                              )}
                            </>
                          ))}
                      </div>

                      {(lead.lead_source === "manual" ||
                        lead.lead_source === "form") && (
                        <div className="flex flex-col mx-2 lg:mx-0 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                          {lead.email && lead.email !== "" && (
                            <div className="">
                              <p>
                                <strong>Email: </strong>
                                {lead.email}
                              </p>
                            </div>
                          )}
                          {lead.notes && lead.notes !== "" && (
                            <div className="">
                              <p>
                                <strong>Notes: </strong>
                                {lead.notes}
                              </p>
                            </div>
                          )}
                          {lead.tags[0]?.service !== undefined && (
                            <div className="">
                              <p>
                                <strong>Service Needed: </strong>
                                {lead.tags[0]?.service}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {lead.locationId !== "AAA Call Center" &&
                        lead.locationId !== "AAA Form Website" &&
                        lead.lead_source !== "manual" &&
                        lead.lead_source !== "form" && (
                          <div className="flex flex-col mx-2 lg:mx-0 gap-4 bg-white p-4 rounded-2xl mb-4 shadow-lg">
                            <div className="">
                              <strong>Messages:</strong>
                            </div>
                            {messagesShow &&
                              messagesShow.map((message) => (
                                <div
                                  key={message.id}
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <div className="grid grid-cols-1 lg:grid-cols-4">
                                    <p className="flex flex-col p-2">
                                      <strong>Date:</strong>{" "}
                                      {new Intl.DateTimeFormat("en-US", {
                                        timeZone: "America/Los_Angeles", // Zona horaria de California
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                        hour12: true,
                                      }).format(new Date(message.dateAdded))}
                                    </p>
                                    <p className="flex flex-col p-2">
                                      <strong>Duration:</strong>{" "}
                                      {message.meta !== undefined &&
                                      message.meta.call.duration
                                        ? `${Math.floor(
                                            message.meta.call.duration / 60
                                          )} minutes ${
                                            message.meta.call.duration % 60
                                          } seconds`
                                        : "N/A"}
                                    </p>
                                    {message.meta !== undefined && (
                                      <p className="flex flex-col p-2">
                                        <strong>Status:</strong>{" "}
                                        {message.meta.call.status}
                                      </p>
                                    )}
                                    <p className="flex flex-col p-2">
                                      <strong>Type:</strong>{" "}
                                      {message.messageType || "Call"}
                                    </p>
                                  </div>
                                  {message.messageType === "TYPE_CALL" && (
                                    <>
                                      {message.meta.call.status !==
                                        "no-answer" && (
                                        <>
                                          <button
                                            onClick={() =>
                                              handleOpenModalRecording(message)
                                            }
                                            className="mt-4 hover:text-red-700 text-red-500"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              className="size-10"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                          <div
                                            className={`mt-4 transition-all duration-300 ease-in-out ${
                                              isInfoOpenRecording
                                                ? "opacity-100 max-h-full"
                                                : "opacity-0 max-h-0 overflow-hidden"
                                            }`}
                                          >
                                            <ModalListenRecording
                                              handleCloseModalRecording={
                                                handleCloseModalRecording
                                              }
                                              messageSelected={message}
                                              isModalOpenRecording={
                                                isModalOpenRecording
                                              }
                                              lead={lead}
                                            />
                                          </div>

                                          {/* <button

                                      onClick={() =>
                                        handleOpenModalRate(message)
                                      }
                                      className="px-4 mt-4 py-2 hover:bg-[#FAE7E9] bg-red-500 text-white hover:text-[#CA0712] rounded-md "
                                    >
                                      Call Details
                                    </button> */}
                                          {/* Contenido desplegable */}

                                          <ModalRate
                                            handleCloseModalRate={
                                              handleCloseModalRate
                                            }
                                            messageSelected={message}
                                            isModalOpenRate={isModalOpenRate}
                                            lead={lead}
                                          />
                                          <div
                                            className={`mt-4 transition-all duration-300 ease-in-out ${
                                              isInfoOpenRate
                                                ? "opacity-100 max-h-full"
                                                : "opacity-0 max-h-0 overflow-hidden"
                                            }`}
                                          ></div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}

        {expandedLeadId === null && (
          <>
            <div className="flex flex-col md:flex-row p-2 justify-between mb-4">
              <h2 className="text-2xl font-bold mb-4">
                Leads {selectedClinic?.business_name}
              </h2>

              <div className="flex flex-col lg:flex-row gap-2">
                {!isRecientTable && (
                  <button
                    onClick={refreshLeads}
                    className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 w-24"
                  >
                    Refresh
                  </button>
                )}
                <div className="relative">
                  <input
                    onChange={(e) => {
                      handleSortInputChange(e.target.value);
                    }}
                    placeholder="Search for Leads"
                    className="mx-1 p-2 pr-8" // Añadir padding a la derecha para el botón "x"
                    value={SearchValue}
                  />
                  {SearchValue && (
                    <button
                      onClick={() => handleSortInputChange("")}
                      className="absolute right-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 w-6"
                      style={{ top: "40%", fontSize: 20 }}
                    >
                      &times;
                    </button>
                  )}
                </div>
                <select
                  className="mx-1 text-sm p-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  value={sortBy}
                  onChange={handleSortChange}
                >
                  <option value="timeDesc">Recent Leads</option>
                  <option value="timeAsc">Previous leads</option>
                  <option value="hotLeads">Hot Leads</option>
                </select>
              </div>
            </div>

            <div className="container flex flex-col items-center justify-center mx-auto pb-4">
              <>
                <table className="min-w-full bg-white py-2 px-4 rounded-2xl shadow-lg">
                  <thead className="">
                    <tr className="bg-white rounded-2xl">
                      <th className=" px-2 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-[#CCCCCC] uppercase tracking-wider text-center">
                        Source
                      </th>
                      <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-[#CCCCCC] uppercase tracking-wider">
                        Client Name
                      </th>
                      <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-[#CCCCCC] uppercase tracking-wider">
                        PURPOSE
                      </th>

                      <th className="hidden md:table-cell px-4 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-[#CCCCCC] uppercase tracking-wider">
                        DATE & TIME
                      </th>

                      <th className=" py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-[#CCCCCC] uppercase tracking-wider">
                        STATUS
                      </th>
                      <th className="px-4 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-[#CCCCCC] uppercase tracking-wider">
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentLeads.map((lead) => (
                      <React.Fragment key={lead._id}>
                        <tr>
                          <td className=" flex justify-center items-center px-0 py-4  border-gray-500 px-4">
                            {chooseImgSource(lead)}
                          </td>
                          <td className="px-2 md:px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="flex flex-col justify-center items-start">
                                <span className="text-gray-900 text-sm lg:text-base font-medium">
                                  {lead.name ||
                                    lead.fullName ||
                                    lead.contactName ||
                                    lead.phone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="text-sm text-gray-500">
                              {truncarTexto(lead.service_type) ||
                                lead.tags[0]?.service ||
                                "Call"}
                            </span>
                          </td>

                          <td className="hidden md:table-cell">
                            <div className="flex flex-row items-center gap-2">
                              <span className="text-sm text-gray-500">
                                {formatCreatedAt(lead.lastMessageDate)}
                              </span>
                              <span className="text-sm text-gray-500">
                                {" "}
                                at{" "}
                              </span>

                              <span className="text-sm text-gray-500">
                                {new Date(lead.lastMessageDate).toLocaleString(
                                  "en-US",
                                  {
                                    timeZone: "America/Los_Angeles",
                                    hour12: true,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                  }
                                )}
                              </span>
                            </div>
                          </td>

                          <td>
                            <div className="">
                              {getStatusIcon(lead.lead_status, isOpen)}
                            </div>
                          </td>
                          <td>
                            <button
                              onClick={() => toggleLeadExpansion(lead._id)}
                              className="flex flex-row items-center gap-2 bg-[#FAE7E9 ] hover:bg-[#FAE7E9] text-[#D43A49] text-sm py-2 px-4 rounded cursor-pointer"
                            >
                              <span>View Details</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-4 hidden md:flex"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>

                <div className="mt-4 flex justify-center">
                  <nav
                    className="flex items-center gap-1 rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      onClick={() => paginate(1)}
                      className={`flex items-center justify-center w-10 py-2 border text-sm font-medium ${
                        currentPage === 1
                          ? "hidden" // Oculta el botón si estás en la última página
                          : currentPage === 2
                          ? "hidden"
                          : "text-[#CB1224] rounded-xl hover:bg-red-300"
                      }`}
                    >
                      {"<<"}
                    </button>
                    <button
                      onClick={() => paginate(currentPage - 1)}
                      className={`flex items-center justify-center w-10 py-2 border text-sm font-medium ${
                        currentPage === 1
                          ? "hidden" // Oculta el botón si estás en la última página
                          : "text-[#CB1224] rounded-xl hover:bg-red-300"
                      }`}
                    >
                      {"<"}
                    </button>
                    {Array.from({ length: endPage - startPage + 1 }).map(
                      (_, index) => {
                        const pageIndex = startPage + index; // Calcular el índice de la página
                        return (
                          <button
                            key={pageIndex}
                            onClick={() => paginate(pageIndex)}
                            className={`flex items-center px-4 py-2 border text-sm font-medium ${
                              currentPage === pageIndex
                                ? "bg-[#CB1224] rounded-xl text-white"
                                : "bg-white border-[#CB1224] rounded-xl text-[#CB1224] hover:bg-red-100"
                            }`}
                          >
                            {pageIndex}
                          </button>
                        );
                      }
                    )}
                    <button
                      onClick={() => paginate(currentPage + 1)}
                      className={`flex items-center justify-center w-10 py-2 border text-sm font-medium ${
                        currentPage === totalPages
                          ? "hidden" // Oculta el botón si estás en la última página
                          : "text-[#CB1224] rounded-xl hover:bg-red-300"
                      }`}
                    >
                      {">"}
                    </button>
                  </nav>
                </div>
              </>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeadTable;
