import React, { useState } from "react";
import axios, { all } from "axios";
import globalVariables from "../../../GlobalVariable";

const ModifyUserContent = ({ handleCloseModal, allUsers }) => {
    const [userData, setUserData] = useState({
        _id: "",
        name: "",
        last_name: "",
        email: "",
        password: "",
    });

    const handleUserSelect = (e) => {
        const selectedUserId = e.target.value;
        const selectedUser = allUsers.find((user) => user._id === selectedUserId);

        if (selectedUser) {
            setUserData({
                _id: selectedUser._id,
                name: selectedUser.name,
                last_name: selectedUser.last_name,
                email: selectedUser.email,
                password: "", // La contraseña no debe pre-poblarse
            });
        } else {
            setUserData({
                _id: "",
                name: "",
                last_name: "",
                email: "",
                password: "",
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios
            .patch(`${globalVariables.domain}/user/`, userData, {
                body: {
                    _id: userData._id,
                    name: userData.name,
                    last_name: userData.last_name,
                    email: userData.email,
                    password: userData.password,
                    roles_ids: userData.roles_ids,
                    business_yelp_ids: userData.business_yelp_ids,
                    approved: userData.approved,
                },
            })

            .then((response) => {
                if (response.data.success) {
                    handleCloseModal();
                    alert("User updated successfully");
                } else {
                    console.error("Error in response: ", response.data.message);
                    alert("Error updating user: " + response.data.message);
                }
            })
            .catch((error) => {
                console.error("Error updating user", error);
                alert("Error updating user");
            });
    };

    // const getAllUsers = async () => {
    //     try {
    //         const response = await axios.get(
    //             `${globalVariables.domain}/users/`
    //         );
    //         allUsers = response.data.content;
    // }

    return (
        <div className="flex flex-grow flex-col p-4">
            <h2 className="text-2xl font-bold mb-4">Modify Users</h2>
            <div className="bg-white rounded-lg p-4">
                <div className="mb-4 border-b pb-2">

                    <select
                        id="roles_ids"
                        name="roles_ids"
                        onChange={handleUserSelect}
                        required
                        className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value="" disabled selected>
                            Select User
                        </option>
                        {allUsers.map((user) => (
                            <option key={user._id} value={user._id}>
                                {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                            </option>
                        ))}
                    </select>
                </div>
                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                    <label className="text-sm text-[#979797]">
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={userData.name}
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <label className="text-sm text-[#979797]">
                        Last Name:
                        <input
                            type="text"
                            name="last_name"
                            value={userData.last_name}
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <label className="text-sm text-[#979797]">
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <label className="text-sm text-[#979797]">
                        Password:
                        <input
                            type="password"
                            name="password"
                            value={userData.password}
                            onChange={handleInputChange}
                            className="mt-1 block w-full border rounded-md p-2 border-gray-300 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <div className="flex justify-end col-start-1 col-span-2 md:col-span-1 md:col-start-2">
                        <button
                            type="submit"
                            className="w-full lg:w-1/2 bg-green-500 text-white py-2 rounded hover:bg-green-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModifyUserContent;
