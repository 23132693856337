"use client";

import React, { useState, useEffect } from "react";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFire,
    faBan,
    faClock,
    faLock,
    faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import globalVariables from "../GlobalVariable";
import ReCAPTCHA from "react-google-recaptcha";
import Turnstile, { useTurnstile } from "react-turnstile";

import "../App.css";

const FormAaron = ({ idTrackGoogleAds, id2 }) => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        service: "",
    });

    const [errors, setErrors] = useState({});
    const [formSuccess, setFormSuccess] = useState(false);
    const [captchaIsPassed, setCaptchaIspassed] = useState(false);
    const [vefifyCaptcha, setVerifyCaptcha] = useState(false);

    const turnstile = useTurnstile();

    const handleChange = (e) => {
        // console.log(e);
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name.trim()) newErrors.name = "This field is required";
        if (!formData.phone.trim()) newErrors.phone = "This field is required";
        if (!formData.service.trim() || formData.service === "Select Service")
            newErrors.service = "This field is required";
        return newErrors;
    };

    const sendSms = async (to, body) => {
        try {
            const response = await axios.post(`${globalVariables.domain}/sms`, {
                body: body,
                to: to,
            });
            setFormSuccess(true);
            setFormData({
                name: "",
                phone: "",
                service: "",
            });

            // Reinicia el Turnstile
            if (turnstile) {
                turnstile.reset();
            }

            setCaptchaIspassed(false); // Reinicia el estado del captcha
            if (response.data.success) {
                // console.log("siuuuu");
            }
        } catch (error) {
            console.error(error);
        }
    };

    function obtenerTimestampActual() {
        return Date.now();
    }

    const gtag_report_conversion = (url) => {
        const callback = () => {
            if (typeof url !== "undefined") {
                window.location = url;
            }
        };
        if (window.gtag) {
            window.gtag("event", "conversion", {
                send_to: `${idTrackGoogleAds}/${id2}`,
                event_callback: callback,
            });
        }
        return false;
    };

    useEffect(() => {
        // Cargar el script de Google Tag Manager dinámicamente
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${idTrackGoogleAds}`;
        document.head.appendChild(script);

        // Configurar gtag una vez que el script esté cargado
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            window.gtag = gtag;
            gtag("js", new Date());
            gtag("config", `${idTrackGoogleAds}`);
        };

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const saveLead = async () => {
        try {
            const data = {
                conversationId: "N/A",
                locationId: "manual",
                lastMessageDate: obtenerTimestampActual(),
                fullName: formData.name,
                tags: [formData, "aaron web form"],
                // email: formData.email,
                phone: formData.phone,
            };
            const response = await axios.post(
                `${globalVariables.domain}/lead_systematik/`,
                {
                    conversationId: data.conversationId,
                    locationId: data.locationId,
                    lastMessageDate: data.lastMessageDate,
                    business_yelp_id: "wxAl4NGuDUhwHgCcllvTMw",
                    //   lastMessageType: data.lastMessageType,
                    //   lastMessageBody: data.lastMessageBody,
                    //   contactId: data.contactId,
                    fullName: data.fullName,
                    //   contactName: data.contactName,
                    phone: data.phone,
                    tags: data.tags,
                    //   companyName: data.companyName,
                    // email: data.email,
                    //   lead_source: data.lead_source,
                    //   messages: data.messages,
                    lead_source: "form",
                }
            );

            // Llama a gtag_report_conversion
            gtag_report_conversion();
        } catch (error) {
            console.error("Error updating lead status:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            if (!captchaIsPassed) {
                setVerifyCaptcha(true);
                return;
            }
            await saveLead();
            setErrors({});
            // console.log("Form Submitted", formData);
            // Aquí puedes agregar la lógica de envío POST.

            const timestamp = new Intl.DateTimeFormat("en-US", {
                timeZone: "America/Los_Angeles",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            }).format(new Date());
            const body = `🚨 New Lead from Website 🚨
        Name: ${formData.name}
        Phone: ${formData.phone}
        Service: ${formData.service}
        Timestamp: ${timestamp}`;

            const to3 = "+14085090579";

            await sendSms(to3, body);
            // await new Promise((resolve) => setTimeout(resolve, 15000));

            // return; /////
        }
    };

    const handleRecaptchaChange = () => {
        setCaptchaIspassed(true);
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-transparent">
            <div className="max-w-xl rounded-lg">
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.name
                                ? "border-red-500 focus:ring-red-500"
                                : "border-gray-300 focus:ring-blue-500"
                                }`}
                        />
                        {errors.name && (
                            <p className="mt-1 text-sm text-red-500">{errors.name}</p>
                        )}
                    </div>
                    <div>
                        <input
                            type="number"
                            name="phone"
                            placeholder="Phone Number"
                            value={formData.phone}
                            onChange={handleChange}
                            className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.phone
                                ? "border-red-500 focus:ring-red-500"
                                : "border-gray-300 focus:ring-blue-500"
                                }`}
                        />
                        {errors.phone && (
                            <p className="mt-1 text-sm text-red-500">{errors.phone}</p>
                        )}
                    </div>

                    <div>
                        <select
                            name="service"
                            value={formData.service}
                            onChange={handleChange}
                            className={`w-full px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 ${errors.service
                                ? "border-red-500 focus:ring-red-500"
                                : "border-gray-300 focus:ring-blue-500"
                                }`}
                        >
                            <option value="" disabled selected>
                                Select Service
                            </option>
                            <option value="Emergency Plumbing">Emergency Plumbing</option>
                            <option value="Drain Cleaning & Repair">Drain Cleaning & Repair</option>
                            <option value="Water Heater Services">Water Heater Services</option>
                            <option value="Toilet & Faucet Repair/Installation">Toilet & Faucet Repair/Installation</option>
                            <option value="Sewer Line Services">Sewer Line Services</option>
                            <option value="Garbage Disposal Services">Garbage Disposal Services</option>
                            <option value="Pipe Repair & Replacement">Pipe Repair & Replacement</option>
                            <option value="Gas Line Services">Gas Line Services</option>
                            <option value="Commerical Plumbing">Commerical Plumbing</option>
                            <option value="New Construction & Remodels">New Construction & Remodels</option>
                        </select>
                        {errors.service && (
                            <p className="mt-1 text-sm text-red-500">{errors.service}</p>
                        )}
                    </div>
                    {/* <div>
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              className={`w-full px-4 py-2 border rounded-lg bg-white focus:outline-none focus:ring-2 ${
                errors.service
                  ? "border-red-500 focus:ring-red-500"
                  : "border-gray-300 focus:ring-blue-500"
              }`}
            >
              <option value="Select Service" disabled selected>
                Select Service
              </option>
              <option value="Heating Services">Air Duct Cleaning</option>
              <option value="Heating Services">Dryer Vent Cleaning</option>
            </select>
            {errors.service && (
              <p className="mt-1 text-sm text-red-500">{errors.service}</p>
            )}
          </div> */}
                    <div>
                        <Turnstile
                            sitekey="0x4AAAAAAA11EXR57l9M1Gd2"
                            onVerify={(token) => {
                                fetch("/login", {
                                    method: "POST",
                                    body: JSON.stringify({ token }),
                                }).then(async (response) => {
                                    if (!response.ok) {
                                        setCaptchaIspassed(true);
                                    }
                                });
                            }}
                            theme="light"
                        // fixedSize={true}
                        />
                    </div>

                    {!captchaIsPassed && vefifyCaptcha && (
                        <p className="mt-1 text-sm text-red-500">This field is required</p>
                    )}
                    <div>
                        <button
                            type="submit"
                            className="w-full px-4 py-2 text-white bg-[#CA0712] rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                        >
                            Request Service
                        </button>
                    </div>

                    <div>
                        {formSuccess && (
                            <p style={{ color: "#fff" }}>
                                ✅ Your submission was successful.
                            </p>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FormAaron;
