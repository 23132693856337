import React, { useState, useEffect } from "react";
import axios from "axios";
import globalVariables from "../../GlobalVariable";

const ModalListenRecording = ({
  handleCloseModalRecording,
  messageSelected,
  isModalOpenRecording,
  lead,
}) => {
  const [recordingBlob, setRecordingBlob] = useState(null);
  const [transcriptions, setTranscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoadingTranscription, setIsloadingTranscription] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState(null);

  const getRecording = async () => {
    setLoading(true);

    if (messageSelected.recording_url) {
      // Si ya existe una URL de grabación, la usamos directamente
      setRecordingBlob(messageSelected.recording_url);
      setRecordingUrl(messageSelected.recording_url);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/recording`,
        {
          headers: {
            message_id: messageSelected.id,
            location_id: messageSelected.locationId,
          },
          responseType: "arraybuffer",
        }
      );

      const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
      setRecordingBlob(audioBlob);
      setRecordingUrl(URL.createObjectURL(audioBlob));
    } catch (error) {
      setRecordingBlob(null);
      setRecordingUrl(null);
      console.error("Error al obtener la grabación:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTranscription = async () => {
    setIsloadingTranscription(true);

    // if (messageSelected.recording_url) {
    // }
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/transcription_ai/${messageSelected.id}`,
        {
          headers: {
            message_id: messageSelected.id,
            location_id: messageSelected.locationId,
          },
        }
      );

      setTranscriptions(Array.isArray(response.data) ? response.data : []);
      // console.log(transcriptions, "transcription");

      // console.log(isLoadingTranscription, transcriptions, "verify");
    } catch (error) {
      setTranscriptions([]);
      console.error("Error al obtener las transcripciones:", error);
    } finally {
      setIsloadingTranscription(false);
    }
  };

  // const getTranscriptionCallRail = async () =>{

  // }

  useEffect(() => {
    if (isModalOpenRecording) {
      getTranscription();
      getRecording();
    } else {
      setIsloadingTranscription(false);
    }
  }, [isModalOpenRecording]);

  useEffect(() => {
    return () => {
      if (recordingUrl && recordingBlob instanceof Blob) {
        URL.revokeObjectURL(recordingUrl);
      }
    };
  }, [recordingUrl]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      {recordingBlob && (
        <div className="flex flex-col mx-2 lg:mx-0 gap-4 lg:p-4 mb-4">
          <div>
            <p className="font-bold mb-4">Recording</p>

            {loading ? (
              <p className="mt-4 text-gray-500">Loading recording...</p>
            ) : recordingUrl ? (
              <audio controls className="mt-4 w-full">
                <source src={recordingUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <p className="mt-4 text-red-500">No recording available</p>
            )}

            <div className="mt-6">
              <p className="font-bold mb-2">Transcriptions:</p>
              <div className="max-h-64 overflow-y-auto border-t border-gray-200 pt-2">
                {isLoadingTranscription ? (
                  <p className="text-gray-500">Loading...</p>
                ) : Array.isArray(transcriptions) &&
                  transcriptions.length > 0 ? (
                  <ul className="space-y-2">
                    {transcriptions.map((transcription, index) =>
                      transcription.text || transcription.phrase ? (
                        <li key={index} className="border-b py-2">
                          <p>
                            <strong>Time:</strong>{" "}
                            {formatTime(parseFloat(transcription.start))}
                          </p>
                          <p>
                            <strong>Speaker:</strong>{" "}
                            {transcription.speaker?.toString() || ""}
                          </p>
                          <p>
                            <strong>Text:</strong>{" "}
                            {transcription.text || transcription.phrase}
                          </p>
                        </li>
                      ) : null
                    )}
                  </ul>
                ) : (
                  <p className="text-gray-500">No transcriptions available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalListenRecording;
