// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./screen/Login";
import Dashboard from "./screen/Dashboard/Dashboard";
import FormAaa from "./screen/form_aaa";
import HomePage from "./AAA/index";
import Statitics from "./screen/statitics";
import PromotionForm from "./screen/PromotionForm";
import SupportForm from "./screen/Support";
import AfterHoursForm from "./screen/AAACallCenter";
import FormAdams from "./screen/form_adams";
import FormAaron from "./screen/form_aaron";
import FormAaronOrganic from "./screen/form_aaron_organic";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/form_aaa"
          element={
            <FormAaa
              idTrackGoogleAds={"AW-11536860637"}
              id2={"AOZmCJ_DhY0aEN2Lmv0q"}
            />
          }
        />
        <Route path="/form_adams" element={<FormAdams />} />
        <Route path="/form_aaron" element={<FormAaron />} />
        <Route path="/form_aaron_organic" element={<FormAaronOrganic />} />
        <Route path="/Home" element={<HomePage />} />
        <Route path="/statitics" element={<Statitics />} />
        <Route path="/promos" element={<PromotionForm />} />
        <Route path="/support" element={<SupportForm />} />
        <Route path="/aaacallcenter" element={<AfterHoursForm />} />
      </Routes>
    </Router>
  );
};

export default App;
