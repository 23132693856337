import React, { useState, useEffect } from "react";
import axios from "axios";
import globalVariables from "../../../GlobalVariable";
import { SpinnerCircularSplit } from "spinners-react";
import LeadTable from "./ClientTable";

const LeadsContent = ({
  selectedClinic,
  setSelectedClinic,
  onClinicClick,
  fetchLeads,
  tagsForm,
  tagsGoogle,
  tagsYelpCall,
  isOpen,
  businesses,
  rolUser,
  user,
}) => {
  const [leads, setLeads] = useState([]);

  const get_data = async () => {
    setLeads([]);
    const all_leads = await fetchLeads(selectedClinic.business_yelp_id);
    setLeads(all_leads);
  };

  useEffect(() => {
    if (selectedClinic !== null) {
      get_data();
    }
  }, [selectedClinic]);

  return (
    <div className="lg:p-4 min-h-screen">
      {leads.length > 0 ? (
        <LeadTable
          rolUser={rolUser}
          setSelectedClinic={setSelectedClinic}
          onClinicClick={onClinicClick}
          businesses={businesses}
          leads={leads}
          setLeads={setLeads}
          fetchLeads={fetchLeads}
          selectedClinic={selectedClinic}
          isRecientTable={false}
          tagsForm={tagsForm}
          tagsGoogle={tagsGoogle}
          tagsYelpCall={tagsYelpCall}
          isOpen={isOpen}
          user={user}
          get_data={get_data}
        />
      ) : (
        <>
          {!isOpen && (
            <div className="absolute top-1/2 left-[46%] lg:left-[55%]">
              <SpinnerCircularSplit
                size={30}
                thickness={102}
                speed={140}
                color="#CB1224"
                secondaryColor="rgba(0, 0, 0, 0.44)"
                className="z-10"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LeadsContent;
