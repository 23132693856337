import React, { useState } from "react";
import Logo from "../../../img/Logo.png";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ onNavigate, currentView, isOpen, setIsOpen, rolUser }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const isAdmin = rolUser === "66ec7fc619e08e4bf824f210";
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      {/* Botón de Hamburguesa
            <button
                className="lg:hidden fixed top-0 right-2 p-2 active:bg-[#FAE7E9] focus:outline-none rounded-full z-50"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? (
                    // SVG de la "X" (blanco)
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#CA0712"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                    </svg>
                ) : (
                    // SVG de la hamburguesa (blanco)

                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#CA0712"
                        className="w-6 h-6"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                )}
            </button> */}

      <div
        className={`fixed lg:static flex flex-col w-full overflow-y-auto lg:w-48 bg-white z-50 text-[#6C6C6C] h-screen transform transition-transform duration-300 ease-in-out ${isOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0 z-50"
          } z-50`}
      >
        <div className="flex items-center p-4 h-16">
          <img src={Logo} alt="Logo" className="w-24 " />
        </div>

        <div className="flex flex-col p-4">
          <nav className="">
            {/* Main Menu */}
            <span className="pb-4 text-sm">Main Menu</span>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => onNavigate("dashboard")}
                  className={`flex items-center w-full p-2 text-base rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712] ${currentView === "dashboard"
                    ? "bg-[#FAE7E9] text-[#CA0712]"
                    : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605"
                    />
                  </svg>
                  Dashboard
                </button>
              </li>
              <li>
                <button
                  onClick={() => onNavigate("leads") }
                  className={`flex items-center w-full p-2 text-base rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712] ${currentView === "leads" ? "bg-[#FAE7E9] text-[#CA0712]" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>
                  Leads
                </button>
              </li>
              <li>
                <button
                  onClick={() => onNavigate('newlead')}
                  className={`flex items-center w-full p-2 text-base rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712] ${currentView === 'newlead' ? 'bg-[#FAE7E9] text-[#CA0712]' : ''
                    }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                  </svg>

                  New Lead
                </button>
              </li>
            </ul>
            <div className="border-b border-[#E6E6E6] my-4"></div>
            <span className="pb-4 text-sm">Users</span>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => onNavigate("edituser")}
                  className={`flex items-center w-full p-2 text-base rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712] ${currentView === "edituser"
                    ? "bg-[#FAE7E9] text-[#CA0712]"
                    : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                    />
                  </svg>
                  Edit Users
                </button>
              </li>
              {isAdmin && (
                <li>
                  <button
                    onClick={() => onNavigate("modifyusers")}
                    className={`flex items-center w-full p-2 text-base rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712] ${currentView === "modifyusers"
                      ? "bg-[#FAE7E9] text-[#CA0712]"
                      : ""
                      }`}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                    Modify Users
                  </button>
                </li>
              )}
              {isAdmin && (
                <li>
                  <button
                    onClick={() => onNavigate("manage")}
                    className={`flex items-center w-full p-2 text-base rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712] ${currentView === "manage"
                      ? "bg-[#FAE7E9] text-[#CA0712]"
                      : ""
                      }`}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-5 mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                      />
                    </svg>
                    Manage
                  </button>
                </li>
              )}
            </ul>
            {/* Account */}
            <div className="border-b border-[#E6E6E6] my-4"></div>
            <ul className="space-y-2">
              <span className="pb-4 text-sm">Account</span>

              <li>
                <a
                  href="#"
                  className="flex items-center p-2 text-base  rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  Settings
                </a>
              </li>
            </ul>
          </nav>
          <div className="mt-auto">
            <button
              onClick={handleLogout}
              className="flex flex-row w-full p-2 text-base text-left text-[#CB1224] rounded-lg hover:bg-[#FAE7E9] hover:text-[#CA0712]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25"
                />
              </svg>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
