import React, { useState, useEffect } from "react";
import axios from "axios";
import globalVariables from "../../GlobalVariable";

const ModalRate = ({
  handleCloseModalRate,
  messageSelected,
  isModalOpenRate,
  lead,
}) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseRate, setResponseRate] = useState(null);

  const getCallDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${globalVariables.domain}/lead_systematik/rate`,
        {
          headers: {
            message_id: messageSelected.id,
            location_id: messageSelected.locationId,
            lead: JSON.stringify(lead),
          },
        }
      );

      // console.log(response.data, "Details");
      setLoading(false);

      if (response.data.is_ai === undefined) {
        setResponseRate(false);
      } else {
        setResponseRate(response.data.is_ai);
      }
      setDetails(response.data.rate || null);
      // console.log(details, "Details");
    } catch (error) {
      console.error("Error al obtener las transcripciones ai:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log(messageSelected)
    getCallDetails();
  }, [messageSelected]);

  const [detailsData, setDetailsData] = useState([]);

  useEffect(() => {
    if (details !== null) {
      setDetailsData(parseHtmlToData(details));
    }
  }, [details]);

  const parseHtmlToData = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    const listItems = doc.querySelectorAll("li");

    return Array.from(listItems).map((item) => {
      const [key, value] = item.textContent.split(": ");
      return { key, value };
    });
  };

  return (
    <div className="flex flex-col mx-2 lg:mx-0 gap-4 lg:p-4 mb-4">
      <div className="">
        {/* Título del modal */}
        <p className="font-bold mb-4">Extracted Call Details</p>

        {/* Contenido desplazable */}
        <div className="">
          {loading ? (
            <p className="mt-4 text-gray-500">Loading...</p>
          ) : details !== null &&
            details &&
            responseRate !== null &&
            responseRate === false &&
            details.call_summary_and_sales_optimization_suggestions ? (
            <div className="flex flex-col gap-4 mt-4 text-gray-700 space-y-3">
              <div className="rounded-lg bg-white shadow-md p-6 mb-4">
                <div className="flex gap-4 text-gray-800">
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div className="flex flex-col col-span-2 gap-2">
                      <span className="font-bold text-sm lg:text-xl text-black mb-2">
                        Call Summary:
                      </span>
                      <span>
                        {details
                          ?.call_summary_and_sales_optimization_suggestions
                          ?.call_overview ||
                          details?.CallSummaryAndSalesOptimizationSuggestions
                            ?.CallOverview ||
                          "Not Stated"}
                      </span>
                    </div>

                    <div className="flex flex-col gap-2 w-full rounded-lg border border-gray-300 p-4 col-span-2 ">
                      <span className="font-bold text-sm lg:text-xl text-black pb-4">
                        Agent Details
                      </span>
                      <div className="flex flex-row">
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Agent Name:
                          </span>
                          <span>
                            {details?.agent_and_client_details?.agent_name ||
                              details?.agentandclientdetails?.AgentName ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Client Name:
                          </span>
                          <span>
                            {details?.agent_and_client_details?.client_name ||
                              details?.agentandclientdetails?.ClientName ||
                              "Not Stated"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 w-full rounded-lg border border-gray-300 p-4 col-span-2 ">
                      <span className="font-bold text-sm lg:text-xl text-black pb-4">
                        Call Handling Details
                      </span>
                      <div className="flex flex-row">
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Total Hold Duration Time:
                          </span>
                          <span>
                            {details?.call_handling_details
                              ?.total_hold_duration_time ||
                              details?.CallHandlingDetails
                                ?.TotalHoldDurationTime ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Appointment Booked:
                          </span>
                          <span>
                            {details?.call_handling_details
                              ?.appointment_booked ||
                              details?.CallHandlingDetails?.AppointmentBooked ||
                              "Not Stated"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 w-full rounded-lg border border-gray-300 p-4 col-span-2 ">
                      <span className="font-bold text-sm lg:text-xl text-black pb-4">
                        Call Quality & Sales Ratings
                      </span>
                      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Call Sentiment Before Call:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.call_sentiment_before_call ||
                              details?.CallQualityAndSalesRatings
                                ?.CallSentimentBeforeCall ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Call Sentiment After Call:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.call_sentiment_after_call ||
                              details?.CallQualityAndSalesRatings
                                ?.CallSentimentAfterCall ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Inbound Sales Call Best Practices:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.inbound_sales_call_best_practices ||
                              details?.CallQualityAndSalesRatings
                                ?.InboundSalesCallBestPractices ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Greeting Quality:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.greeting_quality ||
                              details?.CallQualityAndSalesRatings
                                ?.GreetingQuality ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Clarity and Confidence:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.clarity_and_confidence ||
                              details?.CallQualityAndSalesRatings
                                ?.ClarityAndConfidence ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Script Adherence:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.script_adherence ||
                              details?.CallQualityAndSalesRatings
                                ?.ClarityAndConfidence ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Objection Handling:
                          </span>
                          <span>
                            {details?.call_quality_and_sales_ratings
                              ?.objection_handling ||
                              details?.CallQualityAndSalesRatings
                                ?.ClarityAndConfidence ||
                              "Not Stated"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 w-full rounded-lg border border-gray-300 p-4 col-span-2 ">
                      <span className="font-bold text-sm lg:text-xl text-black pb-4">
                        Client Engagement & Sales Opportunities
                      </span>
                      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 gap-4">
                        <div className="flex  flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Customer Engagement Level:
                          </span>
                          <span>
                            {details?.client_engagement_and_sales_opportunities
                              ?.customer_engagement_level ||
                              details?.CallHandlingDetails
                                ?.TotalHoldDurationTime ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Decision Status:
                          </span>
                          <span>
                            {details?.client_engagement_and_sales_opportunities
                              ?.decision_status ||
                              details?.ClientEngagementAndSalesOpportunities
                                ?.DecisionStatus ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Missed Sales Cues:
                          </span>
                          <span>
                            {details?.client_engagement_and_sales_opportunities
                              ?.missed_sales_cues ||
                              details?.ClientEngagementAndSalesOpportunities
                                ?.MissedSalesCues ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Booking Efficiency:
                          </span>
                          <span>
                            {details?.client_engagement_and_sales_opportunities
                              ?.BookingEfficiency ||
                              details?.ClientEngagementAndSalesOpportunities
                                ?.BookingEfficiency ||
                              "Not Stated"}
                          </span>
                        </div>
                        <div className="flex flex-col w-full gap-2">
                          <span className="font-semibold text-black">
                            Escalation Needed:
                          </span>
                          <span>
                            {details?.client_engagement_and_sales_opportunities
                              ?.escalation_needed ||
                              details?.client_EngagementAndSalesOpportunities
                                ?.EscalationNeeded ||
                              "Not Stated"}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col col-span-2 gap-2">
                      <span className="font-semibold text-black">
                        How To Convert This Lead Next Time:
                      </span>
                      {(() => {
                        const suggestions =
                          details
                            ?.call_summary_and_sales_optimization_suggestions
                            ?.how_to_convert_this_lead_next_time || {};

                        return Object.entries(suggestions).map(
                          ([key, value]) => (
                            <div key={key}>
                              <span className="font-semibold text-gray-700 capitalize">
                                {key.replace(/_/g, " ")}
                              </span>
                              <span>: {value || "Not Stated"}</span>
                            </div>
                          )
                        );
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : details !== null &&
            !details.call_summary_and_sales_optimization_suggestions ? (
            <div className="flex flex-col gap-4 mt-4 text-gray-700 space-y-3">
              <div className="rounded-lg bg-white shadow-md p-6 mb-4">
                <div className="flex gap-4 text-gray-800">
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {detailsData.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col bg-gray-100 p-4 rounded-md shadow"
                      >
                        <span className="font-semibold">{item.key}:</span>
                        {item.key.toLowerCase().includes("phone") ? (
                          <a
                            href={`tel:${item.value}`}
                            className="text-blue-500 underline"
                          >
                            {item.value}
                          </a>
                        ) : (
                          <span className="text-gray-600">{item.value}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <span>No data found</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalRate;
