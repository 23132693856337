import React, { useState, useEffect } from "react";
import Logo from "../../../img/Logo.png";
import axios from "axios";
import globalVariables from "../../../GlobalVariable";
import { useNavigate } from "react-router-dom";

const NavbarConten = ({ isOpen, setIsOpen, rolUser }) => {
  const [user, setUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // Estado para la búsqueda
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false); // Estado para las notificaciones
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar la visibilidad del menú de opciones
  const navigate = useNavigate();

  const [allUsers, setAllUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    // roles_ids: [],
    // business_yelp_ids: [],
  });
  const isAdministrador = rolUser === "66ec7fc619e08e4bf824f210";
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const [isOpenModal, setIsOpenModal] = useState(false);
  // Función para alternar la visibilidad del menú de opciones
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const openModal = () => {
    setIsOpenModal(true);
    setIsMenuOpen(false); // Cierra el menú al abrir el modal
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  // Función para manejar la búsqueda
  const handleSearch = (e) => {
    e.preventDefault();
    console.log("Búsqueda:", searchQuery); // Aquí puedes implementar la lógica de búsqueda
  };

  // Función para alternar la visibilidad de las notificaciones
  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const toggleSearch = () => {
    setIsOpenSearch(!isOpenSearch);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    if (token) {
      const fetchData = async () => {
        try {
          // Obtener roles
          const rolesResponse = await axios.get(
            `${globalVariables.domain}/role`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setRoles(rolesResponse.data.content);

          // Obtener datos del usuario
          const userResponse = await axios.get(
            `${globalVariables.domain}/user/${user_id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (userResponse.data.success) {
            setUser(userResponse.data.content);
            const userRoles = userResponse.data.content.roles_ids;
            const userRolesDetails = rolesResponse.data.content.filter((role) =>
              userRoles.includes(role._id)
            );
            const adminRole = userRolesDetails.some(
              (role) => role.role_name === "admin"
            );
            setIsAdmin(adminRole);

            setUserData({
              _id: userResponse.data.content._id,
              name: userResponse.data.content.name,
              last_name: userResponse.data.content.last_name,
              email: userResponse.data.content.email,
              password: "",
              roles_ids: userResponse.data.content.roles_ids,
              business_yelp_ids: userResponse.data.content.business_yelp_ids,
            });
          }

          // Obtener todos los usuarios
          const allUsersResponse = await axios.get(
            `${globalVariables.domain}/user/all_users`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (allUsersResponse.data.success) {
            setAllUsers(allUsersResponse.data.content);
          }
        } catch (error) {
          console.error("Error al obtener datos:", error);
        }
      };

      fetchData();
    }
  }, []);

  //   useEffect(() => {
  //     console.log(user);
  //   }, [user]);

  return (
    <div className="p-4 bg-white">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between lg:hidden">
          <img src={Logo} alt="Logo" className="w-24 " />
          <div className="flex items-center gap-2">
            <button
              onClick={toggleSearch}
              className="p-2 bg-[#F9F9F9] rounded-full hover:bg-gray-100 hover:text-gray-900 text-[#6C6C6C] focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#979797"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
            {/* <button
              onClick={toggleNotifications}
              className="p-2 bg-[#F9F9F9] rounded-full hover:bg-gray-100 hover:text-gray-900 text-[#6C6C6C] focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-5"
              >
                <path
                  fillRule="evenodd"
                  d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </button><button
              onClick={toggleNotifications}
              className="p-2 bg-[#F9F9F9] rounded-full hover:bg-gray-100 hover:text-gray-900 text-[#6C6C6C] focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-5"
              >
                <path
                  fillRule="evenodd"
                  d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                  clipRule="evenodd"
                />
              </svg>
            </button> */}
            {isAdministrador && (
              <>
                {/* Avatar y menú de opciones */}
                <div className="flex">
                  {/* Botón del avatar */}
                  <button
                    onClick={toggleMenu}
                    className="p-2 bg-[#F9F9F9] rounded-full hover:bg-gray-100 hover:text-gray-900 text-[#6C6C6C] focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Menú de opciones */}
                  {isMenuOpen && (
                    <div className="absolute top-12 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-50">
                      <ul className="py-2">
                        <li>
                          <button
                            // onClick={() => console.log("Ir a Configuración")}
                            className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
                          >
                            switch account
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
            <button
              className="lg:hidden relative p-2 bg-[#F9F9F9] text-[#6C6C6C] hover:text-[#CA0712] active:text-[#CA0712] hover:bg-gray-100 active:bg-[#FAE7E9] focus:outline-none rounded-full z-50"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                // SVG de la "X" (blanco)
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              ) : (
                // SVG de la hamburguesa (blanco)

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between ">
          <p className="flex flex-row text-lg lg:text-sm">
            Welcome to AzucarAI,{" "}
            <span className="font-bold text-orange-500 ml-2">
              {" "}
              {user?.name} {user?.last_name}.
            </span>
          </p>
          <div className="hidden lg:flex gap-10 flex-row items-center">
            <form onSubmit={handleSearch} className="flex items-center">
              <button
                type="submit"
                className=" p-1 pl-2 bg-[#F9F9F9] rounded-l-lg hover:bg-gray-100 focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#979797"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
              <input
                type="text"
                placeholder="Search for Leads"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="p-1 rounded-r-lg bg-[#F9F9F9] text-[#979797] text-sm hover:bg-gray-100 focus:outline-none"
              />
            </form>
{/* 
            <div className="border-l border-[#E6E6E6] h-6"></div>

            <div className="relative">
              <button
                onClick={toggleNotifications}
                className="p-2 bg-[#F9F9F9] rounded-full hover:bg-gray-100 hover:text-gray-900 text-[#6C6C6C] focus:outline-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-5 "
                >
                  <path
                    fillRule="evenodd"
                    d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div> */}
            {isAdministrador && (
              <>
                {/* Avatar y menú de opciones */}
                <div className="flex">
                  {/* Botón del avatar */}
                  <button
                    onClick={toggleMenu}
                    className="p-2 bg-[#F9F9F9] rounded-full hover:bg-gray-100 hover:text-gray-900 text-[#6C6C6C] focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {/* Menú de opciones */}
                  {isMenuOpen && (
                    <div className="absolute top-12 right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-50">
                      <ul className="py-2">
                        <li>
                          <button
                            // onClick={openModal}
                            className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
                          >
                            switch account
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
            {/* Modal para cambiar de cuenta */}
            {isOpenModal && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div
                  className="absolute inset-0 bg-black opacity-50"
                  onClick={closeModal}
                ></div>
                <div className="bg-white rounded-lg shadow-lg z-50 w-1/3 p-6">
                  <h2 className="text-lg font-semibold">Cambiar Cuenta</h2>
                  <form>
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700">
                        Nueva Cuenta:
                      </label>
                      <input
                        type="text"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-2 py-1"
                      />
                    </div>
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-md"
                        onClick={closeModal}
                      >
                        Cancelar
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white rounded-md"
                      >
                        Cambiar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>

          {isOpenSearch && (
            <div className="absolute top-0 left-0 h-16 w-full bg-[#F9F9F9] rounded-lg shadow-lg z-50">
              <form
                onSubmit={handleSearch}
                className="flex items-center h-full"
              >
                <button
                  onClick={toggleSearch}
                  className="px-2 pl-2 bg-[#F9F9F9] rounded-l-lg hover:bg-gray-100 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#979797"
                    className="size-7"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  placeholder="Search for Leads"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="px-4 rounded-r-lg h-full w-full text-[#979797] text-sm hover:bg-gray-100 focus:outline-none"
                />
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavbarConten;
