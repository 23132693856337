import React, { useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

// Función para generar colores dinámicos
const generateColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const hue = (i * (360 / count)) % 360; // Espacia colores uniformemente
    colors.push(`hsl(${hue}, 70%, 50%)`); // Tonos brillantes
  }
  return colors;
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  index,
  colors,
}) => {
  const offset = 30; // Distancia adicional desde el borde del gráfico
  const radius = outerRadius + offset; // Posición de las etiquetas fuera del gráfico
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const xLineStart = cx + outerRadius * Math.cos(-midAngle * RADIAN);
  const yLineStart = cy + outerRadius * Math.sin(-midAngle * RADIAN);

  const xLineEnd =
    cx + (outerRadius + offset - 10) * Math.cos(-midAngle * RADIAN);
  const yLineEnd =
    cy + (outerRadius + offset - 10) * Math.sin(-midAngle * RADIAN);

  const textAnchor = x > cx ? "start" : "end";

  return (
    <g>
      {/* Línea desde el gráfico al punto de conexión */}
      <line
        x1={xLineStart}
        y1={yLineStart}
        x2={xLineEnd}
        y2={yLineEnd}
        stroke={colors[index]} // Color correspondiente al segmento
        strokeWidth={1.5}
      />
      {/* Línea horizontal hacia la etiqueta */}
      <line
        x1={xLineEnd}
        y1={yLineEnd}
        x2={x}
        y2={y}
        stroke={colors[index]} // Color correspondiente al segmento
        strokeWidth={1.5}
      />
      {/* Etiqueta de texto */}
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={textAnchor}
        dominantBaseline="central"
        style={{ fontSize: "10px", fontWeight: "bold" }}
      >
        {`${name} ${(percent * 100).toFixed(1)}%`}
      </text>
    </g>
  );
};

const PieChartComponent = ({ data }) => {
  const COLORS = generateColors(data.length); // Genera colores según la cantidad de datos

  return (
    <div className="flex flex-col lg:flex-row z-0">
      <ResponsiveContainer height={300}>
        <PieChart>
          <Tooltip formatter={(value, name) => [`${value}`, `${name}`]} />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={(props) =>
              renderCustomizedLabel({ ...props, colors: COLORS })
            }
            outerRadius={90}
            fill="#8884d8"
            dataKey="value"
            className="z-10"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index]}
                className="z-10"
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

      {/* Tabla adicional para mostrar los datos */}
      <div className="mt-6 z-0">
        <table className="table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">Platform</th>
              <th className="border border-gray-300 px-4 py-2">Leads</th>
              <th className="border border-gray-300 px-4 py-2">
                Percentage (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((entry, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="border border-gray-300 px-4 py-2 flex items-center">
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      backgroundColor: COLORS[index],
                      marginRight: "8px",
                    }}
                  ></div>
                  {entry.name}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {entry.value}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {(
                    (entry.value /
                      data.reduce((sum, item) => sum + item.value, 0)) *
                    100
                  ).toFixed(1)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PieChartComponent;
