import React, { useState, useEffect } from "react";
import "./PromotionForm.css";
import axios from "axios";
import Logo from "../img/Logo.png";
import Spinner from "./components/spinner";

const PromotionForm = () => {
  const [formData, setFormData] = useState({
    clinicName: "",
    promotionName: "",
    offerDetails: "",
    startDate: "",
    endDate: "",
    adOption: "",
    googleAmount: "",
    fbIgAmount: "",
    keyDetails: "",
    additionalInfo: "",
  });

  const [files, setFiles] = useState(null); // Estado para el archivo
  const [fileUrl, setFileUrl] = useState(""); // Estado para la URL de la imagen
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]); // Guardar múltiples archivos en el estado
  };

  async function postFiles(files) {
    const uploadedUrls = [];

    for (const file of files) {
      const fileFormData = new FormData();
      fileFormData.append("file", file); // Cambiar "image" a "file"

      try {
        const response = await axios.post(
          `https://api.samedayvet.com/file`,
          fileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success === true) {
          uploadedUrls.push(response.data.content[0]); // Guardar la URL subida
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    return uploadedUrls;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let fileUrls = [];
    if (files && files.length > 0) {
      fileUrls = await postFiles(files); // Subir múltiples archivos
    }

    const pacificDate = new Date().toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
      dateStyle: "short",
      timeStyle: "medium",
    });

    // console.log(formData.fbIgAmount);

    try {
      const response = await axios.post(
        "https://v1.nocodeapi.com/adan/google_sheets/lCQGiYbQvMTmoilR/addRows?tabId=Sheet1",
        [
          {
            "Clinic Name": formData.clinicName,
            "Promotion Name": formData.promotionName,
            "Offer Details": formData.offerDetails,
            "Start Date": formData.startDate,
            "End Date": formData.endDate,
            "Paid Ad (Yes/No)": formData.adOption,
            "Google Amount": formData.googleAmount,
            "FB/IG Amount": formData.fbIgAmount,
            "Key Details": formData.keyDetails,
            "Additional Info": formData.additionalInfo,
            "File URLs": fileUrls.join("\n") || "No files uploaded",
            "Submission Date": pacificDate,
          },
        ],
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // return;

      setShowModal(true);
      setFormData({
        clinicName: "",
        promotionName: "",
        offerDetails: "",
        startDate: "",
        endDate: "",
        adOption: "",
        googleAmount: "",
        fbIgAmount: "",
        keyDetails: "",
        additionalInfo: "",
      });
      setFiles(null); // Resetea el archivo seleccionado
      setLoading(false);
    } catch (error) {
      console.error("Error al enviar:", error.response?.data || error.message);
      alert("Error submitting form");
      setLoading(false);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="max-w-lg mx-auto p-8 bg-white shadow-md rounded-lg space-y-6"
      >
        <h2 className="text-2xl font-bold text-center">
          Clinic Promotion Form
        </h2>
        <p className="text-md text-center ">
          Please fill out any upcoming promotions you want us to advertise on
          social and newsletters
        </p>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Clinic Name (<span className="text-red-500">required</span>)
          </label>
          <input
            type="text"
            name="clinicName"
            required
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.clinicName}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name of the Promotion (
            <span className="text-red-500">required</span>)
          </label>
          <input
            type="text"
            name="promotionName"
            required
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.promotionName}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            What’s the Offer? (<span className="text-red-500">required</span>)
          </label>
          <input
            type="text"
            name="offerDetails"
            required
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.offerDetails}
            onChange={handleChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Promotion Dates (<span className="text-red-500">required</span>)
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="date"
              name="startDate"
              required
              className="mt-1 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.startDate}
              onChange={handleChange}
            />
            <input
              type="date"
              name="endDate"
              required
              className="mt-1 p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <p>
            This information is usually enough to start a promotion; optional
            details are below if you'd like to expand.
          </p>
        </div>
        <fieldset className="mb-4">
          <legend className="font-medium">Paid Ad? (optional)</legend>

          <label className="block mb-2">
            <input
              type="checkbox"
              name="googleAd"
              onChange={handleChange}
              className="mr-2"
            />
            Google
            <input
              type="text"
              name="googleAmount"
              placeholder="Amount per month"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.googleAmount}
              onChange={handleChange}
            />
          </label>
          <label className="block mb-2">
            <input
              type="checkbox"
              name="fbIgAd"
              onChange={handleChange}
              className="mr-2"
            />
            FB/IG
            <input
              type="text"
              name="fbIgAmount"
              placeholder="Amount per month"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={formData.fbIgAmount}
              onChange={handleChange}
            />
          </label>
        </fieldset>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Key Details, fine Print or Conditions (optional)
          </label>
          <textarea
            name="keyDetails"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.keyDetails}
            onChange={handleChange}
            rows="4"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Upload Any Photos or Graphics (optional)
          </label>
          <input
            type="file"
            name="files"
            multiple
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={handleFileChange}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Anything Else You’d Like Us to Know? (optional)
          </label>
          <textarea
            name="additionalInfo"
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.additionalInfo}
            onChange={handleChange}
            rows="4"
          />
        </div>

        <button
          type="submit"
          className="mt-6 w-full py-3 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition duration-300"
        >
          {loading ? <Spinner /> : "Submit"}
        </button>
      </form>
      {showModal && (
        <div className="fixed inset-0 bg-white flex justify-center items-center">
          <div className="flex flex-col justify-center items-center bg-white rounded-lg p-8  max-w-sm w-full">
            <img src={Logo} width={300} alt="Logo" />
            <h3 className="text-xl text-center my-4">
              Thank you! Your promotion has been submitted. We'll contact you if
              we have any questions.
            </h3>
            <p className="text-center mb-6">-AzucarAI</p>
            <button
              onClick={() => {
                // setShowModal(false); // Cerrar el modal
                window.location.reload(); // Desplazar al inicio con animación
              }}
              className="block mx-auto bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 transition duration-300"
            >
              Add additional promotion
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PromotionForm;
