"use client";

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFire,
  faBan,
  faClock,
  faLock,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import globalVariables from "../../../GlobalVariable";
import Yelp from "../../../img/yelp.png";
import google from "../../../img/google.png";
import internet from "../../../img/internet.png";
import yelp_call from "../../../img/yelp_call.png";
import aaa_call_center from "../../../img/aaa_call_center.png";
import LeadTable from "./ClientTable";

import aaa_form_website from "../../../img/aaa_form_website.png";
import ModalListenRecording from "../../components/ModalListenRecording";
import { ChevronDown } from "lucide-react";
import CardsBusiness from "./cards";
import Footer from "./Footer";

// Lista de colores de Tailwind CSS para texto
const tailwindTextColors = [
  "text-red-500",
  "text-blue-500",
  "text-green-500",
  "text-yellow-500",
  "text-indigo-500",
  "text-orange-500",
  "text-gray-500",
];

const getColorIndexFromId = (id) => {
  // Convertir el ID a un número usando un hash simple
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = (hash << 5) - hash + id.charCodeAt(i);
    hash |= 0; // Convertir a entero de 32 bits
  }
  // Usar el valor absoluto del hash y obtener el índice dentro del rango de colores
  return Math.abs(hash) % tailwindTextColors.length;
};

const DashboardContent = ({
  onClinicClick,
  url,
  setClinic,
  fetchLeads,
  selectedClinic,
  setSelectedClinic,
  tagsForm,
  tagsGoogle,
  tagsYelpCall,
  user,
}) => {
  const [leads, setLeads] = useState([]);

  const [yelpIds, setYelpIds] = useState("");

  const [businessSelected, setBusinessSelected] = useState("");
  const [recentLeads, setRecentLeads] = useState([]);

  useState(() => {
    const businessYelpIds = localStorage.getItem("business_yelp_ids");
    // console.log(businessYelpIds, "eeeeeeeeee");

    if (!businessYelpIds.includes(",")) {
      setYelpIds([businessYelpIds]);
      // fetchLeads(businessYelpIds);
      setBusinessSelected(businessYelpIds);
    } else {
      // Si la cadena contiene valores separados por comas, conviértelo en un array
      const businessYelpIdsArray = businessYelpIds
        ? businessYelpIds.split(",")
        : [];

      // Verifica el resultado
      // console.log(businessYelpIdsArray);

      setYelpIds(businessYelpIdsArray);
    }
  }, []);

  // useEffect(() => {
  //   console.log(leads, "leadddddss");
  // }, [leads]);

  const [businessAvalaible, setBusinessAvalaible] = useState("");

  const getBusiness = async () => {
    // console.log(user, "user");
    try {
      const response = await axios.get(
        `${globalVariables.domain}/business/by_user/${user._id}`,
        {}
      );

      const allBusiness = response.data.content;

      const businessPermited = allBusiness.filter((business) =>
        yelpIds.includes(business.business_yelp_id)
      );
      // console.log(allBusiness, "businesss");
      setSelectedClinic(allBusiness[0]);
      // Actualizar el estado con los negocios permitidos
      setBusinessAvalaible(businessPermited);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // console.log(user._id);\
    if (user !== null) {
      // console.log(user, "user");
      getBusiness();
    }
  }, [user]);

  const get_data = async () => {
    const all_leads = await fetchLeads(selectedClinic.business_yelp_id);

    // Ordenar los leads por createdAt en orden descendente
    const sorted_leads = all_leads.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    // console.log(sorted_leads);

    // Obtener solo los primeros 5 elementos más recientes
    const first_five_leads = sorted_leads.slice(0, 5);

    setLeads(first_five_leads);
  };

  useEffect(() => {
    // console.log(selectedClinic, "selectedClinic")
    if (selectedClinic !== null) {
      get_data();
    }
  }, [selectedClinic]);

  return (
    <div className="flex flex-grow p-2 md:p-4 md:pl-8 container flex flex-col  mx-auto pb-4">
      <h2 className=" text-2xl lg:text-3xl font-bold mb-4 text-[#2d2d2d]">
        Dashboard
      </h2>

      {/* Mostrar las tarjetas de business */}

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {businessAvalaible && businessAvalaible.length > 0 ? (
          businessAvalaible.map((business, index) => {
            // Obtener el índice del color basado en el ID de la clínica

            return (
              <CardsBusiness
                position={index}
                key={business.business_yelp_id}
                business={business}
                onClinicClick={onClinicClick}
                fetchLeads={fetchLeads}
                url={url}
              />
            );
          })
        ) : (
          <p className="text-gray-600">No businesses available.</p>
        )}
      </div>

      {/* Mostrar los leads más recientes */}
      <div className="">
        <h2 className="text-3xl font-bold mb-4 text-[#2d2d2d]">
          Recent 5 Leads
        </h2>
        {leads.length > 0 ? (
          <div className="">
            <LeadTable
              leads={leads}
              setLeads={setLeads}
              fetchLeads={fetchLeads}
              selectedClinic={selectedClinic}
              isRecientTable={true}
              tagsForm={tagsForm}
              tagsGoogle={tagsGoogle}
              tagsYelpCall={tagsYelpCall}
              user={user}
              get_data={get_data}
            />
          </div>
        ) : (
          <p className="text-gray-600">There are no recent leads.</p>
        )}
      </div>
    </div>
  );
};

export default DashboardContent;
