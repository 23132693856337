import React, { useEffect, useState } from "react";
import PieChartComponent from "./pieChart"; // Importa el gráfico

const ModalStatitics = ({
  setModalStatitics,
  leads,
  modalStatitics,
  mapping,
  tagsForm,
  tagsGoogle,
  tagsYelpCall,
}) => {
  const handleOutsideClick = (e) => {
    if (e.target.id === "modal-overlay") {
      setModalStatitics(false); // Cierra el modal al hacer clic fuera
    }
  };

  // Calcular las fechas iniciales (últimos 30 días)
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const [startDate, setStartDate] = useState(
    thirtyDaysAgo.toISOString().split("T")[0]
  ); // Fecha de inicio predeterminada
  const [endDate, setEndDate] = useState(today.toISOString().split("T")[0]); // Fecha de fin predeterminada

  const [leadsYelp, setLeadsYelp] = useState([]);
  const [leadsForm, setLeadsForm] = useState([]);
  const [leadsYelpCall, setLeadsYelpCall] = useState([]);
  const [leadsGoogle, setLeadsGoogle] = useState([]);
  const [user, setUser] = useState(null);
  const [currentViewPieChart, setCurrentViewPieChart] = useState("LeadSource");

  useEffect(() => {
    if (!leads || leads.length === 0) return;

    const filteredLeads = leads.filter((lead) => {
      const leadDate = new Date(lead.createdAt); // Convertir lead.createdAt a objeto Date
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      // Filtrar por rango de fechas
      return (!start || leadDate >= start) && (!end || leadDate <= end);
    });

    // Filtrar leads según las condiciones
    const yelpLeads = filteredLeads.filter((lead) => lead.lead_id);
    const formLeads = filteredLeads.filter(
      (lead) => lead.lead_source === "form"
    );
    const yelpCallLeads = filteredLeads.filter(
      (lead) => lead.lead_source === "yelp_call"
    );
    const googleLeads = filteredLeads.filter(
      (lead) => lead.lead_source === "google"
    );

    setLeadsYelp(yelpLeads);
    setLeadsForm(formLeads);
    setLeadsYelpCall(yelpCallLeads);
    setLeadsGoogle(googleLeads);
  }, [leads, startDate, endDate]);

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log(
    //   leadsYelp.length,
    //   leadsForm.length,
    //   leadsYelpCall.length,
    //   leadsGoogle.length
    // );
    setData([
      { name: "Yelp", value: leadsYelp.length },
      { name: "Form", value: leadsForm.length },
      { name: "Yelp Call", value: leadsYelpCall.length },
      { name: "Google", value: leadsGoogle.length },
    ]);
  }, [
    leadsYelp,
    leadsForm,
    leadsYelpCall,
    leadsGoogle,
    tagsForm,
    tagsGoogle,
    tagsYelpCall,
  ]);

  const [data2, setData2] = useState([]);

  const normalizeServiceType = (serviceType) => {
    if (mapping === false) {
      return serviceType;
    } else {
      return serviceType;
      // return mapping[serviceType];
    }
  };

  useEffect(() => {
    if (!leads || leads.length === 0) return;

    const filteredLeads = leads.filter((lead) => {
      const leadDate = new Date(lead.createdAt); // Convertir createdAt a Date
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : null;

      // Filtrar por rango de fechas
      return (!start || leadDate >= start) && (!end || leadDate <= end);
    });

    const serviceTypeCounts = {};

    filteredLeads.forEach((lead) => {
      if (lead.service_type) {
        const normalized = normalizeServiceType(lead.service_type);
        serviceTypeCounts[normalized] =
          (serviceTypeCounts[normalized] || 0) + 1;
      }
    });

    // Filtrar los servicios con un valor mayor o igual a 5
    const filteredData = Object.entries(serviceTypeCounts)
      .filter(([_, value]) => value >= 5)
      .map(([name, value]) => ({ name, value }));

    setData2(filteredData);
  }, [leads, startDate, endDate, tagsForm, tagsGoogle, tagsYelpCall]);

  return (
    <div className="p-2 z-10">
      <div className="flex flex-col bg-white rounded-xl shadow-lg p-4 items-start gap-4 mt-4 w-full mb-8 z-10">
        {/* Rango de fechas */}
        <div className="flex flex-col gap-4 lg:flex-row w-full justify-between items-center border-b ">
          <div className="flex flex-row">
            <button
              className={`flex flex-col gap-2 px-4 py-2 transition 
           ${
             currentViewPieChart === "LeadSource" ? "bg-[#fbd9c4]" : "bg-white"
           }`}
              onClick={() => setCurrentViewPieChart("LeadSource")}
            >
              <span className="font-bold text-lg text-black">Lead Source</span>
              <span className="text-orange-500 font-bold">
                Total Leads: {data.reduce((sum, item) => sum + item.value, 0)}
              </span>
            </button>

            <div className="w-[1px] h-full bg-gray-300"></div>

            <button
              className={`flex flex-col gap-2 px-4 py-2 transition 
            ${
              currentViewPieChart === "ServiceTypes"
                ? "bg-[#fbd9c4]"
                : "bg-white"
            }`}
              onClick={() => setCurrentViewPieChart("ServiceTypes")}
            >
              <span className="font-bold text-lg text-black">
                Service Types
              </span>
              <span className="text-orange-500 font-bold">
                Total Leads: {data2.reduce((sum, item) => sum + item.value, 0)}
              </span>
            </button>
          </div>

          <div className="flex flex-col lg:flex-row items-center justify-center gap-2 lg:gap-4">
            <label className="block mb-2 font-bold">Select Date Range:</label>
            <div className="flex items-center gap-4">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border px-3 py-2 rounded"
              />
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border px-3 py-2 rounded"
              />
            </div>
            <div className="flex items-center gap-2 text-gray-600 font-bold">
              {/* Mostrar el rango de días calculado */}
              {startDate && endDate
                ? `${Math.max(
                    0,
                    Math.ceil(
                      (new Date(endDate) - new Date(startDate)) /
                        (1000 * 60 * 60 * 24)
                    )
                  )} days`
                : "Select a valid range"}
              {/* Botón de reset */}
              <button
                onClick={() => {
                  const today = new Date();
                  const thirtyDaysAgo = new Date(today);
                  thirtyDaysAgo.setDate(today.getDate() - 30);
                  setStartDate(thirtyDaysAgo.toISOString().split("T")[0]); // Formato YYYY-MM-DD
                  setEndDate(today.toISOString().split("T")[0]); // Formato YYYY-MM-DD
                }}
                // className="bg-white-500 text-red color-red px-4 py-2 rounded hover:bg-red-600 transition"
                className="border border-red-500 bg-white text-red-500 px-4 py-2 rounded hover:bg-red-500 hover:text-white transition"
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        {/* Gráficos */}
        <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
          {/* pie chart 1 */}
          {currentViewPieChart === "LeadSource" && (
            <div className="w-full z-10">
              {data.reduce((sum, item) => sum + item.value, 0) > 1 ? (
                <PieChartComponent data={data} />
              ) : (
                <p className="text-gray-600">
                  Not enough data to display the chart.
                </p>
              )}
            </div>
          )}
          {/* pie chart 2 */}
          {currentViewPieChart === "ServiceTypes" && (
            <div className="w-full z-10">
              {data2.reduce((sum, item) => sum + item.value, 0) > 1 ? (
                <PieChartComponent data={data2} />
              ) : (
                <p className="text-gray-600">
                  Not enough data to display the chart.
                </p>
              )}
            </div>
          )}
          {/* finish */}
        </div>
      </div>
    </div>
  );
};

export default ModalStatitics;
