import React, { useState } from "react";
import axios from "axios";
import globalVariables from "../../../GlobalVariable";

const EditUserConten = ({
    userData,
    setUserData,
    allUsers,
}) => {
    const [successMessage, setSuccessMessage] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();


        axios
            .patch(`${globalVariables.domain}/user/`, userData, {
                body: {
                    _id: userData._id,
                    name: userData.name,
                    last_name: userData.last_name,
                    email: userData.email,
                    password: userData.password,
                    roles_ids: userData.roles_ids,
                    business_yelp_ids: userData.business_yelp_ids,
                    approved: userData.approved,
                },
            })
            .then((response) => {
                if (response.data.success) {
                    setSuccessMessage("User created successfully!");
                    alert("User updated successfully");
                    setTimeout(() => {
                        setSuccessMessage("");

                    }, 3000);
                } else {
                    console.error("Error in response:", response.data.message);
                    alert("Error updating user: " + response.data.message);
                    setTimeout(() => {
                        setSuccessMessage("");

                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Error updating user", error);
                setSuccessMessage("An error occurred while creating the user."); // Mensaje genérico de error
                alert("Error updating user");
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    return (
        <div className="flex flex-grow flex-col p-4">
            <h2 className="text-2xl font-bold mb-4">Edit User</h2>
            <div className="bg-white rounded-lg p-4">
                <h3 className="text-lg font-semibold mb-4 border-b pb-2">User Information</h3>
                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                    <label className="text-sm text-[#979797]">
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={userData.name || ""} // Usa userData.name
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full border rounded-md p-2 border-gray-500 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <label className="text-sm text-[#979797]">
                        Last Name:
                        <input
                            type="text"
                            name="last_name"
                            value={userData.last_name || ""} // Usa userData.last_name
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full border rounded-md p-2 border-gray-500 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <label className="text-sm text-[#979797]">
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={userData.email || ""} // Usa userData.email
                            onChange={handleInputChange}
                            required
                            className="mt-1 block w-full border rounded-md p-2 border-gray-500 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <label className="text-sm text-[#979797]">
                        Password:
                        <input
                            type="password"
                            name="password"
                            value=""
                            onChange={handleInputChange}
                            className="mt-1 block w-full border rounded-md p-2 border-gray-500 focus:outline-none focus:ring focus:ring-blue-300"
                        />
                    </label>
                    <div className="flex justify-end col-start-1 col-span-2 md:col-span-1 md:col-start-2">
                        <button
                            type="submit"
                            className="w-full lg:w-1/2 bg-green-500 text-white py-2 rounded hover:bg-green-600"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
            {/* Mensaje de éxito */}
            {successMessage && (
                <div className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-lg shadow-lg z-40">
                    Form submitted successfully!
                </div>
            )}
        </div>
    );
};

export default EditUserConten;